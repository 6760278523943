import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from "../../_helpers/config.class";
import { AuthenticationService } from "../../_services/authentication.service";
import {
  EMployeeViewModel,
  EmployeePostPaySlipsViewModel,
  PassportStatusViewModel,
} from "./employee-class";
import { Observable } from "rxjs";
import { VisaExpense } from "../settings/setting.class";
import { LocalService } from "../../_services/local.service";

@Injectable()
export class EmployeeServices {
  headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    _authenticationService: AuthenticationService,
    private _localService: LocalService
  ) {
    this.headers.set("Content-Type", "application/x-www-form-urlencoded");
    this.headers.set(
      "Authorization",
      "Bearer " + _authenticationService.getToken()
    );
  }

  public saveEmployee(obj: EMployeeViewModel) {
    debugger;
    const formData = new FormData();
    if (obj.DPFile != null) {
      if ((obj.DPFile.Name = "DP")) {
        formData.append("DP", obj.DPFile);
      }
    }
    if (obj.passportFile != null) {
      if ((obj.passportFile.Name = "PassPort")) {
        formData.append("PassPort", obj.passportFile);
      }
    }
    if (obj.Visafile != null) {
      if ((obj.Visafile.Name = "Visa")) {
        formData.append("Visa", obj.Visafile);
      }
    }
    if (obj.Insurancefile != null) {
      if ((obj.Insurancefile.Name = "Insurance")) {
        formData.append("Insurance", obj.Insurancefile);
      }
    }
    if (obj.DLFile != null) {
      if ((obj.DLFile.Name = "DrivingLicenc")) {
        formData.append("DrivingLicenc", obj.DLFile);
      }
    }
    if (obj.EmiratesFile != null) {
      if ((obj.EmiratesFile.Name = "Emirates")) {
        formData.append("Emirates", obj.EmiratesFile);
      }
    }
    if (obj.InsurranceFile != null) {
      if ((obj.InsurranceFile.Name = "Insurance")) {
        formData.append("Insurance", obj.InsurranceFile);
      }
    }
    debugger;
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveEmployee")}`,
      formData
    );
  }
  public getEmployeeList(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string
  ) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetEmployeeList")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }

  public GetEmployeeListforDashboard() {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetEmployeeListforDashboard")}`,
      { Id: null }
    );
  }
  public getEmployeeById(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetEmployeeById")}`,
      { Id: id }
    );
  }
  public getIssueItems(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetIssueItems")}`,
      { Id: id }
    );
  }
  public getEmployeeServicesHistory(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetEmployeeServicesHistory")}`,
      { Id: id }
    );
  }
  public changeStatus(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "ChangeStatus")}`,
      { Id: id }
    );
  }

  public getBalanceForEmp(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Reporting", "GetBalanceForEmp")}`,
      { Id: id }
    );
  }

  employeCodeListbyEmployeId(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "employeCodeListbyEmployeId")}`,
      { Id: id }
    );
  }

  SaveEmployeCodes(obj) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveEmployeCodes")}`,
      obj
    );
  }

  SaveWPSStatusCodes(obj:any) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveWPSStatus")}`,
      obj
    );
  }

  DeleteEmployeCodes(obj) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "DeleteEmployeCodes")}`,
      obj
    );
  }

  changeDefaultEmployeCode(id: number, code: string, PId){
    return this.http.post(
      `${Config.getControllerUrl("Employee", "ChangeDefaultEmployeCode")}`,
      { Id: id, Code: code, categoryId: PId }
    );
  }

  //passportStatus
  public getPassportStatus(id: number) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "GetEmployePassportListByEmployeeId"
      )}`,
      { Id: id }
    );
  }

   //WPSStatus
   public getWPSStatus(id: number) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "GetWPSHistory"
      )}`,
      { Id: id }
    );
  }

  public getPassportSerialNumber(id: number) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "GetEmployeeSerialNumber"
      )}`,
      { Id: id }
    );
  }
  public savePassportData(obj:any) {
    debugger;
    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveEmployePassport")}`,
      formData
    );
  }
  public SaveEmployeeCodeByExcel(obj:any) {
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveEmployeCodesByExcel")}`,
      formData
    );
  }
  public SaveEmployeeByExcel(obj:any) {
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveEmployeeByExcel")}`,
      formData
    );
  }

  public CheckEmployeeCodeByExcel(file:any){
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      `${Config.getControllerUrl("Employee", "CheckEmployeeCodeByExcel")}`,
      formData
    );
  }

  // visa
  public validateVisaCode(code: string) {
    return this.http.post(
      `${Config.getControllerUrl("Visa", "ValidateVisaCode")}`,
      { Code: code }
    );
  }
  public getVisaList(searchTerm: string): Observable<any> {
    return this.http.post(`${Config.getControllerUrl("Visa", "GetVisaList")}` ,  {
     
      Search: searchTerm  
    });
  }
  public getVisaById(id: number): Observable<any> {
    return this.http.post(`${Config.getControllerUrl("Visa", "GetVisaById")}`, {
      Id: id,
    });
  }

  public saveVisaMasterDetail(obj: any) {
    debugger;
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Visa", "SaveVisaMasterDetail")}`,
      formData
    );
  }
  public removeVisaMasterDetail(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("Visa", "DeleteVisaMasterDetail")}`,
      obj
    );
  }
  public saveVisaAdjustment(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("Visa", "SaveVisaAdjustment")}`,
      obj
    );
  }

  // visa expense
  public SaveVisaExpenseByExcel(file:any){
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      `${Config.getControllerUrl("Visa", "SaveVisaExpenseByExcel")}`,
      formData
    );
  }
  public getVisaExpenseList(): Observable<any> {
    return this.http.get(
      `${Config.getControllerUrl("VisaExpense", "GetVisaExpenseList")}`
    );
  }
  // Insurance
  public validateInsuranceCode(code: string) {
    return this.http.post(
      `${Config.getControllerUrl("Insurance", "ValidateInsuranceCode")}`,
      { Code: code }
    );
  }
  public getInsuranceList(searchTerm: string): Observable<any> {
    return this.http.post(`${Config.getControllerUrl("Insurance", "GetInsuranceList")}` ,  {
     
      Search: searchTerm  
    });
  }
  public getInsuranceById(id: number): Observable<any> {
    return this.http.post(`${Config.getControllerUrl("Insurance", "GetInsuranceById")}`, {
      Id: id,
    });
  }

  public saveInsuranceMasterDetail(obj: any) {
    debugger;
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Insurance", "SaveInsuranceMasterDetail")}`,
      formData
    );
  }
  public removeInsuranceMasterDetail(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("Insurance", "DeleteInsuranceMasterDetail")}`,
      obj
    );
  }
  public saveInsuranceAdjustment(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("Insurance", "SaveInsuranceAdjustment")}`,
      obj
    );
  }

  // Insurance expense
  public getInsuranceExpenseList(): Observable<any> {
    return this.http.get(
      `${Config.getControllerUrl("InsuranceExpense", "GetInsuranceExpenseList")}`
    );
  }

  public GetEmployePasportReport(){
    return this.http.get(
      `${Config.getControllerUrl("Employee", "GetEmployePassportListReport")}`
    );
  }

  public DownloadEmployeInExcel(){
    return this.http.get(
      `${Config.getControllerUrl("Employee", "DownloadEmployeInExcel")}`
    );
  }

  public getVisaExpenseListByVisaId(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("VisaExpense", "GetVisaExpenseListByVisaId")}`,
      { Id: id }
    );
  }
  public getVisaInstalmentListByVisaId(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("VisaInstalment", "GetVisaInstalmentByVisaId")}`,
      { Id: id }
    );
  }
  public saveVisaExpense(obj, file: File) {
    let fd = new FormData();
    fd.append("Attachment", file);
    fd.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("VisaExpense", "SaveVisaExpense")}`,
      fd
    );
  }
  public deleteVisaExpense(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("VisaExpense", "DeleteVisaExpense")}`,
      obj
    );
  }

  // visa instalments
  public validateVisaInstallmentCode(code: string) {
    return this.http.post(
      `${Config.getControllerUrl(
        "VisaInstalment",
        "ValidateVisaInstallmentCode"
      )}`,
      { Code: code }
    );
  }
  public saveVisaInstalment(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("VisaInstalment", "SaveVisaInstallment")}`,
      obj
    );
  }
  public getVisaInstallmentList(
     skip: number,
    pageSize: number,
    query: string,
    
    
  ) {
    return this.http.post(
      `${Config.getControllerUrl("VisaInstalment", "GetVisaInstallmentList")}` ,
      
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        
       
      }
    );
  }
  public deleteVisaInstallment(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("VisaInstalment", "DeleteVisaInstallment")}`,
      obj
    );
  }
  public getAutoVisaList() {
    return this.http.get(
      `${Config.getControllerUrl("Visa", "GetAutoVisaList")}`
    );
  }

  public saveVisaInstallmentAuto(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("VisaInstalment", "SaveVisaInstallmentAuto")}`,
      obj
    );
  }

  public getInsuranceExpenseListByInsuranceId(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceExpense", "GetInsuranceExpenseListByInsuranceId")}`,
      { Id: id }
    );
  }
  public getInsuranceInstalmentListByInsuranceId(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceInstalment", "GetInsuranceInstalmentByInsuranceId")}`,
      { Id: id }
    );
  }
  public saveInsuranceExpense(obj, file: File) {
    let fd = new FormData();
    fd.append("Attachment", file);
    fd.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("InsuranceExpense", "SaveInsuranceExpense")}`,
      fd
    );
  }
  public deleteInsuranceExpense(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceExpense", "DeleteInsuranceExpense")}`,
      obj
    );
  }

  // Insurance instalments
  public validateInsuranceInstallmentCode(code: string) {
    return this.http.post(
      `${Config.getControllerUrl(
        "InsuranceInstalment",
        "ValidateInsuranceInstallmentCode"
      )}`,
      { Code: code }
    );
  }
  public saveInsuranceInstalment(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceInstalment", "SaveInsuranceInstallment")}`,
      obj
    );
  }
  public getInsuranceInstallmentList(
     skip: number,
    pageSize: number,
    query: string,
    
    
  ) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceInstalment", "GetInsuranceInstallmentList")}` ,
      
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        
       
      }
    );
  }
  public deleteInsuranceInstallment(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceInstalment", "DeleteInsuranceInstallment")}`,
      obj
    );
  }
  public getAutoInsuranceList() {
    return this.http.get(
      `${Config.getControllerUrl("Insurance", "GetAutoInsuranceList")}`
    );
  }

  public saveInsuranceInstallmentAuto(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("InsuranceInstalment", "SaveInsuranceInstallmentAuto")}`,
      obj
    );
  }

  // Security Deposit
  public validateSecurityDepositeCode(code: any) {
    return this.http.post(
      `${Config.getControllerUrl(
        "DepositSecurity",
        "ValidateSecurityDepositeCode"
      )}`,
      { Code: code }
    );
  }
  public saveSecurityDeposit(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("DepositSecurity", "SaveSecurityDeposit")}`,
      obj
    );
  }
  public savePettyCash(obj: any) {
    console.log(obj);

    return this.http.post(
      `${Config.getControllerUrl("EmployeePettyCash", "SaveEmployePetyCash")}`,
      obj
    );
  }
  public getPettyCashList() {
    return this.http.get(
      `${Config.getControllerUrl(
        "EmployeePettyCash",
        "GetEmployeeGroupByPettyCashList"
      )}`
    );
  }
  public saveOrderCOD(model: any) {
    console.log(model);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "SaveEmployeCOD")}`,
      model
    );
  }
  public getCODList() {
    return this.http.get(
      `${Config.getControllerUrl("OrderCOD", "GetOrderCODList")}`
    );
  }
  getCODById(id) {
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetOrderCODById")}`,
      { Id: id }
    );
  }
  public GetInstalmentsByCODId(id: any) {
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetInstalmentsByCODId")}`,
      { Id: id }
    );
  }
  public GetUnPaidCODByEmployeeId(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetUnPaidCODByEmployeeId")}`,
      raw
    );
  }
  public GetUnPaidCODDCByCustomerId(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetUnPaidCODDCByCustomerId")}`,
      raw
    );
  }
  public GetEASCommissionSaleryById(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetEASCommissionSaleryById")}`,
      {Id:raw}
    );
  }
  public GetUnPaidDCCommissionList(toDate) {
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetUnPaidDCCommissionList")}`,
      {ToDate:toDate}
    );
  }
  public GetEmployeeCommissionSalary(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetEmployeeCommissionSalary")}`,
      raw
    );
  }

  public GetDHNCommisionListFromExcel(obj: any) {
    console.log(obj);
    const formData = new FormData();
    // if(obj.file !== )
    formData.append("file", obj.ExcelAttachement);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDHNCommisionListFromExcel")}`,
      formData
    );
  }
  public GetTSCommisionListFromExcel(obj: any) {
    console.log(obj);
    const formData = new FormData();
    // if(obj.file !== )
    formData.append("file", obj.ExcelAttachement);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetTSCommisionListFromExcel")}`,
      formData
    );
  }
  public GetDeliveroCommisionListFromExcel(obj: any) {
    console.log(obj);
    const formData = new FormData();
    // if(obj.file !== )
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDeliveroCommisionListFromExcel")}`,
      formData
    );
  }
  public GetDHNCommissionSalary(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDHNCommissionSalary")}`,
      raw
    );
  }
  public GetTSCommissionSalary(raw: any) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetTSCommissionSalary")}`,
      raw
    );
  }
  public GetEASCommissionSalary (raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetEASCommissionSalary ")}`,
      raw
    );
  }


  public GetPDSCommisionListFromExcel(obj: any, file :any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetPDSCommisionListFromExcel")}`,
      formData
    );
  }

  
  public GetPDSCommissionSalary(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetPDSCommissionSalary")}`,
      raw
    );
  }
  public GetDeliveroCommissionSalary(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDeliveroCommissionSalary")}`,
      raw
    );
  }
  public GetEmployeeZoneCommision(raw: any) {
    console.log(raw);
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetEmployeeZoneCommision")}`,
      raw
    );
  }
  public GetCODListByTypePOS(raw: any) {
    console.log("received raw", raw);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetCODListByTypePOS")}`,
      raw
    );
  }
  /////////////////////////////Commision Region/////////////////////////
  public SaveEmployeeCommission(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SaveEmployeeCommissionSalery")}`,
      formData
    );
  }
  public SaveDHNCommissionSalery(obj: any) {
    debugger
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SaveDHNCommissionSalery")}`,
      formData
    );
  }
  public SaveTSCommissionSalery(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SaveTSCommissionSalery")}`,
      formData
    );
  }
  public SaveEASCommissionSalery(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SaveEASCommissionSalery")}`,
      formData
    );
  }
  public SavePDSCommissionSalery(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData.get('Obj'));
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SavePDSCommissionSalery")}`,
      formData
    );
  }


  public saveRentAgreement(obj: any) {
    const formData = new FormData();
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData.get('Obj'));
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("RentAgreement", "SaveRentAgreement")}`,
      formData
    );
  }


  public SaveDeliveroCommissionSalery(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SaveDeliveroCommissionSalery")}`,
      formData
    );
  }
  public GetDHCommisionListFromExcel(obj: any) {
    debugger;
    const formData = new FormData();
    // if(obj.file !== )
    formData.append("file", obj.excelFile);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);

    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDHCommisionListFromExcel")}`,
      formData
    );
  }
  public GetDHCommissionSaleryById(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDHCommissionSaleryById")}`,
      {Id:id}
    );
  }
  public DeleteDHCommission(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "DeleteDHForm")}`,
      {Id:id}
    );
  }
  public DeleteDHNForm(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "DeleteDHNForm")}`,
      {Id:id}
    );
  }
  public DeleteTSForm(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "DeleteTSForm")}`,
      {Id:id}
    );
  }
  public DeleteEASForm(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "DeleteEASForm")}`,
      {Id:id}
    );
  }
  public DeleteDeliveroForm(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "DeleteDeliveroForm")}`,
      {Id:id}
    );
  }
  public GetDHNCommissionSaleryById(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDHNCommissionSaleryById")}`,
      {Id:id}
    );
  }
  public GetTSCommissionSaleryById(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetTSCommissionSaleryById")}`,
      {Id:id}
    );
  }
  public GetDeliveroFormCommissionSaleryById(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetDeliveroFormCommissionSaleryById")}`,
      {Id:id}
    );
  }
  // PDS Per Day Salary
  ///////////////////////////////////////////////////////////
  public DeletePDSForm(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "DeletePDSForm")}`,
      {Id:id}
    );
  }

  public RemoveRentAgreement(id) {
    return this.http.post(
      `${Config.getControllerUrl("RentAgreement", "RemoveRentAgreement")}`,
      {Id:id}
    );
  }

  public GetPDSCommissionSaleryById(id) {
    return this.http.post(
      `${Config.getControllerUrl("Salary", "GetPDSCommissionSaleryById")}`,
      {Id:id}
    );
  }

  public GetRentAgreementById(id) {
    return this.http.post(
      `${Config.getControllerUrl("RentAgreement", "GetRentAgreementById")}`,
      {Id:id}
    );
  }



  ///////////////////////////////////////////////////////////
  public SaveEmployeeZoneCommission(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("Salary", "SaveEmployeeZoneCommission")}`,
      formData
    );
  }
  public SaveBulkCOD(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "SaveBullkEmployeCOD")}`,
      formData
    );
  }
  public AddBulkDCReturn(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "AddBulkDCReturn")}`,
      formData
    );
  }
  public SaveBulkCommissionReturn(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "SaveBulkCommissionReturn")}`,
      formData
    );
  }
  public SaveBullkPOSCOD(obj: any) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    console.log(formData);
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "SaveBullkPOSCOD")}`,
      formData
    );
  }
  public getDepositListByEmp(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("DepositSecurity", "GetDepositListByEmp")}`,
      { Id: obj }
    );
  }
  public GetPettycashListByEmployeeId(id: string) {
    return this.http.post(
      `${Config.getControllerUrl(
        "EmployeePettyCash",
        "GetPettycashListByEmployeeId"
      )}`,
      { Id: id }
    );
  }
  public GetEmpInstallmentsByEmployeeId(id: string) {
    return this.http.post(
      `${Config.getControllerUrl(
        "EmployeePettyCash",
        "GetInstalmentsByEmployeId"
      )}`,
      { Id: id }
    );
  }
  public getSecurityDepositList() {
    return this.http.get(
      `${Config.getControllerUrl(
        "DepositSecurity",
        "GetTotalSecurityDepositList"
      )}`
    );
  }
  public deleteSecurityDeposit(obj: any) {
    return this.http.post(
      `${Config.getControllerUrl("DepositSecurity", "DeleteSecurityDeposit")}`,
      obj
    );
  }

  // payslip

  public savePaySlip(obj: EmployeePostPaySlipsViewModel) {
    this._localService.dateTransFuckDatesInObject(obj);
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SavePaySlips")}`,
      formData
    );
  }

  public sendPaySlipEmail(obj: EmployeePostPaySlipsViewModel) {
    this._localService.dateTransFuckDatesInObject(obj);
    const formData = new FormData();
    // formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SendPaySlipEmail")}`,
      formData
    );
  }

  
  public getPaySlipsList() {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetPaySlipsList")}`,
      { Id: 0 }
    );
  }
  public getPaySlipsByEmployeeId(id: any) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "GetPaySlipsByEmployeeId")}`,
      { Id: id }
    );
  }

  // event-log

  public getEventList() {
    return this.http.post(
      `${Config.getControllerUrl("Event", "GetEventList")}`,
      null
    );
  }
  public getEventListByEmployeId(id) {
    return this.http.post(
      `${Config.getControllerUrl("Event", "GetEventListByEmployeId")}`,
      { Id: id }
    );
  }

  public saveEventMaster(obj) {
    this._localService.dateTransFuckDatesInObject(obj);
    let fd = new FormData();
    fd.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Event", "SaveEventMaster")}`,
      fd
    );
  }

  public deleteEventMaster(id) {
    return this.http.post(
      `${Config.getControllerUrl("Event", "DeleteEventMaster")}`,
      { Id: id }
    );
  }
  // event-log-type
  public getEventTypeList() {
    return this.http.post(
      `${Config.getControllerUrl("Event", "GetEventTypeList")}`,
      null
    );
  }
  public saveEventType(obj) {
    let fd = new FormData();
    fd.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Event", "SaveEventType")}`,
      fd
    );
  }
  public deleteEventType(id) {
    return this.http.post(
      `${Config.getControllerUrl("Event", "DeleteEventType")}`,
      { Id: id }
    );
  }

  // event comment

  public saveEventComment(obj) {
    let fd = new FormData();
    fd.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Event", "SaveEventComment")}`,
      fd
    );
  }
  public deleteEventComment(id) {
    return this.http.post(
      `${Config.getControllerUrl("Event", "DeleteEventComment")}`,
      { Id: id }
    );
  }
  public getEventCommentList(id) {
    return this.http.post(
      `${Config.getControllerUrl("Event", "GetEventCommentList")}`,
      { Id: id }
    );
  }
  public SaveCODByExcel(obj) {
    const formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "SaveEmployeCODByExcel")}`,
      formData
    );
  }
  public DeleteEmployeCOD(obj) {
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "DeleteEmployeCOD")}`,
      obj
    );
  }
  public GetOrderCODList(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string
  ) {
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetOrderCODList")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }
  public GetBulkCODPaination(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string
  ) {
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetBulkCODPaination")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }
  public GetBulkCODDCPaination(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string
  ) {
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetBulkCODDCPaination")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }
  public GetBulkCODCPaination(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string
  ) {
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetBulkCODCPaination")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }
  public GetBulkCODByTypePaination(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string,
    type:any
  ) {
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetBulkCODByTypePaination")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
        Cid:type
      }
    );
  }
  public GetBulkCodList() {
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetBulkCodList")}`,
      {}
    );
  }
  public GetBulkCodListById(id) {
    return this.http.post(
      `${Config.getControllerUrl("OrderCOD", "GetBulkCodListById")}`,
      {Id:id}
    );
  }
  public GetEmployeSlipsByContract(skip: number, pageSize: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Employee', 'GetEmployeSlipsByContract')}`, {Skip: skip, PageSize: pageSize, Search: searchTerm});
    // return this.http.get(
    //   `${Config.getControllerUrl("Employee", "GetEmployeSlipsByContract")}`
    // );
  }
  public SaveEmployeSlipsByContract(raw) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "SaveEmployeSlipsByContract"
      )}`,
      { Id:raw.id, ToDate:raw.ToDate,FromDate:raw.FromDate,Bit:raw.Bit, returnQty : raw.returnQty}
    );
  }
  /** Employee Attendance module */
  public GetEmployeeAttandanceDetails(date: any, Client_Id: any) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "GetEmployeeAttandanceDetails"
      )}`,
      { Date: date, Id: Client_Id}
    );
  }

  /** Employee Attendance module */
  public GetEASRepost(stardate: any,endtade: any, Client_Id: any) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "GetEASRepost"
      )}`,
      { fromdate: stardate, todate: endtade, Id: Client_Id}
    );
  }

  public GetEEASRepost(stardate: any,endtade: any, Client_Id: any) {
    return this.http.post(
      `${Config.getControllerUrl(
        "Employee",
        "GetEASReportbyEmploye"
      )}`,
      { fromdate: stardate, todate: endtade, Id: Client_Id}
    );
  }

  public SaveEmployeeAttandance(obj) {
    
    return this.http.post(
      `${Config.getControllerUrl("Employee", "SaveEmployeeAttandance")}`,
      obj
    );
  }
  public getEmployeAttandanceList( skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "getEmployeAttandanceList")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }
  public getEmployeAttandanceById(id: number) {
    return this.http.post(
      `${Config.getControllerUrl("Employee", "getEmployeAttandanceById")}`,
      { Id: id }
    );
  }

  public GetRentAgreement(skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('RentAgreement', 'GetRentAgreementList')}`, { skip,
      pageSize,
      query,
      hfId,
      geoLvlCode,
      hfType,
      stockId,
      searchTerm});
  }
  public SaveInsuranceAgrementByExcel(file:any){
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      `${Config.getControllerUrl("Insurance", "SaveInsuranceAgrementByExcel")}`,
      formData
    );
  }
  public SaveVisaAgrementByExcel(file:any){
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      `${Config.getControllerUrl("Visa", "SaveVisaAgrementByExcel")}`,
      formData
    );
  }


}

