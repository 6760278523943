import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from '../../_helpers/config.class';

import { AuthenticationService } from "../../_services/authentication.service";
import { CoustomerContrectViewMode, EmployeeAssiginingModel, CoustomerCashMastModel, CusCashReturn, DirectCashCollectionViewModel, BullkReturnCODViewModel, CCODMastModel, SupplierContrectViewMode } from "./coustomer-class";
import { JsonPipe } from "@angular/common";
import { LocalService } from "../../_services/local.service";

@Injectable()
export class CoustomerServices {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, _authenticationService: AuthenticationService, private _localService: LocalService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken())
  }

  public saveContrct(obj: CoustomerContrectViewMode) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveContrect')}`, obj);
  }
  // Save Supplier Contrct
  public saveSupplierContrct(obj: SupplierContrectViewMode) {
    console.log(obj);
    const formData = new FormData();
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'SaveContrect')}`, formData);
  }


  public getContrectList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, serach?) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetContrectList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: serach });
  }

  //  Supplier Contrect
  public getSupplierContrectList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, serach?) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetContrectList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: serach });
  }


  public getContretcById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetContretcById')}`, { Id: id });
  }
  // 
  public getSupplierContretcById(id: number) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetContretcById')}`, { Id: id });
  }

  public GetBulkCodListById(id: number) {
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetBulkCodListById')}`, { Id: id });
  }
  public GetBulkCustomerCODById(id: number, clientId) {
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetBulkCustomerCODById')}`, { Id: id, Client_Id: clientId });
  }

  public getContrectListDDL(id: string, type: string) {

    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetContrectListDDL')}`, { Code: id, Type: type });
  }
  public getContrectList4PlDDL(id: string, type: string) {

    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetContrectListDDL')}`, { Code: id, Type: type });
  }

  public getAvalibelEmployee(id: string, mode: string) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetAvalibelEmployee')}`, { Code: id, Type: mode });
  }
  public getAvalibel4Pl(id: string, mode: string) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetAvalibelEmployee')}`, { Code: id, Type: mode });
  }
  public getEmployeeDetail(id: number, cid: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetEmployeeDetail')}`, { Id: id, Cid: cid });
  }
  public get4PlDetail(id: number, cid: number) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetEmployeeDetail')}`, { Id: id, Cid: cid });
  }

  public saveEmployForContrect(obj: EmployeeAssiginingModel) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveEmployForContrect')}`, obj);
  }
  public save4PlForContrect(obj: EmployeeAssiginingModel) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'SaveEmployForContrect')}`, obj);
  }

  public getAssingedEmpDtl(id: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetAssingedEmpDtl')}`, { Id: id });
  }
  public getAssinged4PlDtl(id: number) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetAssingedEmpDtl')}`, { Id: id });
  }

  public getAssingedEmployee(id: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetAssingedEmployee')}`, { Id: id });
  }

  public ChangeCOntrectStatus(id: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'ChangeCOntrectStatus')}`, { Id: id });
  }

  public saveCoustomerCash(obj: CoustomerCashMastModel) {
    console.log(obj);
    this._localService.dateTransFuckDatesInObject(obj);
    console.log(obj);
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveCoustomerCash')}`, obj);
  }
  public SaveCDCOD(obj: CCODMastModel) {
    console.log(obj);
    this._localService.dateTransFuckDatesInObject(obj);
    console.log(obj);
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveCDCOD')}`, obj);
  }

  public getRecevingList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, search?) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetRecevingList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Serach: search });
  }
  public GetCCODList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, search?) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetCCODList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Serach: search });
  }
  public getRetrutningList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetRetrutningList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }

  public getRecevingDetail(id: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetRecevingDetail')}`, { Id: id });
  }

  public saveEmpCahsReturnAdjustment(obj) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveEmpCahsReturnAdjustment')}`, obj);
  }
  public saveEmpCahsReturn(obj: CusCashReturn) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveEmpCahsReturn')}`, obj);
  }
  public getRetrutningById(id: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetRetrutningById')}`, { Id: id });
  }

  public saveUnassindEmployee(code: number, date: any, cid: number) {
    debugger;
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'SaveUnassindEmployee')}`, { Id: code, Date: date, Cid: cid });
  }
  public saveUnassind4Pl(code: number, date: any, cid: number) {
    debugger;
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'SaveUnassindEmployee')}`, { Id: code, Date: date, Cid: cid });
  }
  public DeleteCustomerCashReceiving(code: number) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'DeleteCustomerCash')}`, { Id: code});
  }
  public UnAssingEmployee() {
    debugger;
    return this.http.get(`${Config.getControllerUrl('Coustomer', 'UnAssingEmployee')}`);
  }
  public UnAssing4Pl() {
    debugger;
    return this.http.get(`${Config.getControllerUrl('SupplierContract', 'UnAssingEmployee')}`);
  }
  public SaveEmployForContrectByExcel(obj: any) {
    debugger;
    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("Coustomer", "SaveEmployForContrectByExcel")}`,
      formData
    );
  }
  public Save4PlForContrectByExcel(obj: any) {
    debugger;
    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("SupplierContract", "SaveEmployForContrectByExcel")}`,
      formData
    );
  }
  // Direct cash collection

  public GetDCCList() {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'GetDCCList')}`, { Id: 0 });
  }
  public GetDCCPaginationList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'GetDCCPaginationList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: searchTerm });
  }
  public getEmployeDccSettlementPagination(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'getEmployeDccSettlementPagination')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId, Search: searchTerm });
  }
  public GetDCCById(id: number) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'GetDCCById')}`, { Id: id });
  }
  public GetDCCListByEmployeId(id: number) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'GetDCCListByEmployeId')}`, { Id: id });
  }
  public SaveDCC(obj: DirectCashCollectionViewModel) {
    this._localService.dateTransFuckDatesInObject(obj);
    let fd = new FormData();
    fd.append('Attachment', obj.file);
    fd.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'SaveDCC')}`, fd);
  }
  public DeleteDCC(id: number) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'DeleteDCC')}`, { Id: id });
  }

  //Customer COD
  public GetCustomerCODList(id) {
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetBulkCodListByType')}`, { Cid: id });
  }

  public GetCustomerCODListById(id: number, ToDatee: Date) {
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetCODListByCustomerId')}`, { Id: id, ToDatee });
  }

  public SaveCustomerCOD(obj: BullkReturnCODViewModel) {
    debugger
    let fd = new FormData();
    fd.append('Attachment', obj.file);
    fd.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'SaveBullkCustomerCOD')}`, fd);
  }
  public SaveDCCByExcel(obj: any) {
    debugger;
    console.log(obj);
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("DirectCashCollection", "SaveDCCByExcel")}`,
      formData
    );
  }
  public GetDCCSettlementList(ToDatee: Date) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'GetDCCSettlementList')}`, { ToDate: ToDatee });
  }
  public SaveDCCSettlement(obj: any) {
    debugger;
    console.log(obj);
    let formData = new FormData();
    // formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("DirectCashCollection", "SaveDCCSettlement")}`,
      formData
    );
  }
  public getEmployeDccSettlementList() {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'getEmployeDccSettlementList')}`, {});
  }
  public getEmployeDccSettlementById(id) {
    return this.http.post(`${Config.getControllerUrl('DirectCashCollection', 'getEmployeDccSettlementById')}`, { Id: id });
  }
  public GetCashreceivingFromExcel(obj: any) {
    let fd = new FormData();
    fd.append('file', obj.excelFile);
    fd.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetCashreceivingFromExcel')}`, fd);
  }
  public GetCDCODFromExcel(obj: any) {
    let fd = new FormData();
    fd.append('file', obj.excelFile);
    fd.append('Obj', JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetCDCODFromExcel')}`, fd);
  }

  public GetDhnInvoiceAndCustomerCombineEmployee(obj: any) {
    debugger
    return this.http.post(`${Config.getControllerUrl('FourPLInvoice', 'GetDhnInvoiceAndCustomerCombineEmployee')}`, { Id: obj.FK_Dhn_Invoice_Master, Cid: obj.FK_Supplier_Account });
  }

  public Save4PLInvoice(obj: any) {
    
    console.log(obj);
    let formData = new FormData();
    // formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(
      `${Config.getControllerUrl("FourPLInvoice", "Save4PLInvoiceData")}`,
      formData
    );
  }
  public Get4PlInvoiceList(skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('FourPLInvoice', 'Get4PLInvoiceList')}`, { skip,
      pageSize,
      query,
      hfId,
      geoLvlCode,
      hfType,
      stockId,
      searchTerm});
  }

  public Get4PLInvoiceDetailById(id) {
    return this.http.post(
      `${Config.getControllerUrl("FourPLInvoice", "GetFourPLInvoiceDetailById")}`,
      {Id:id}
    );
  }
  public Delete4PLForm(id) {
    return this.http.post(
      `${Config.getControllerUrl("FourPLInvoice", "Delete4PLInvoice")}`,
      {Id:id}
    );
  }
}
