import { EbService } from './../eb-service.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { EBOrdersViewModel } from "../eb-orders-class";
import { StoreService } from "../../store/store.services";
import { EmployeeServices } from "../../employee/employee-services";
import { NotificationService } from "../../../_services/notification.service";
import { CoustomerServices } from "../../coustomer/coustome-services";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ItemViewModel } from '../../store/store-model';
@Component({
  selector: "app-add-eb-orders",
  templateUrl: "./add-eb-orders.component.html",
  styleUrls: ["./add-eb-orders.component.scss"],
})
export class AddEbOrdersComponent implements OnInit {
  @ViewChild("auto") auto;
  public _ebOrderViewModel = new EBOrdersViewModel();
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  customerList:Array<any>=[];
  keyword = "Number";
  data = [];
  paymentMethods: Array<any> = [
    {
      Id: 1,
      Name: "POS",
    },
    {
      Id: 2,
      Name: "CASH",
    },
  ];
  maxDate = new Date();
  employeeAccountList: Array<any> = [];
  constructor(
    private _storeService: StoreService,
    private _employeeService: EmployeeServices,
    private _notificationService: NotificationService,
    private _customerService: CoustomerServices,
    private router: Router,
    private _ebService: EbService
  ) {
    this.randomNumberGenerator();
    this._ebOrderViewModel.PaymentMethod = new ItemViewModel(this.paymentMethods[1].Id,this.paymentMethods[1].Name);
  }

  ngOnInit() {
    this.handleSearchEvents();
    this.maxDate.setHours(this.maxDate.getHours() + 5);
    this._ebOrderViewModel.OrderDate = new Date();
    this.getEmployeeAccountList("", "E");
    this.getCustomerList();
  }
  public getEmployeeAccountList(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("emp list", res);
        if (res) {
          if (event === "E") {
            this.employeeAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  public handleSearchEvents() {
    console.log("cll");
    this.searchSubcription = this.searchEvent
      .pipe(debounceTime(400))
      .subscribe((x: any) => {
        console.log(x);
        if (x) {
          if (x.filter == "FK_Emp_Acc") {
            this.getEmployeeAccountList(x.event, "E");
          }
          // if (x.filter == 'FK_Dabit_Acc') {
          //   this.getCashBankAccount(x.event, 'CaB');
          // }
          // if (x.filter == 'FK_Customer_Acc') {
          //   this.getCustomerAccountList(x.event, 'C');
          // }
        }
      });
  }
  getCustomerList(){
   this._ebService.GetEbCustomersList().subscribe((res:any) => {
     this.customerList = res;
   });
  }
  selectEvent(item) {
    console.log(item);
    this._ebOrderViewModel.CustomerName = item.Name;
    // do something with selected item
    this.auto.close();
  }

  onChangeSearch(val: string) {
    console.log(val);
    this.data = this.customerList;
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }
  onEnter(event) {
    console.log(event);
    this._ebOrderViewModel.CustomerNumber = event;
  }
  randomNumberGenerator() {
    this._ebOrderViewModel.OrderNumber = "";
    const randomString = Date.now() + Math.floor(Math.random() * 100 + 1);
    // let randomString = Math.random().toString(36).substring(7);
    this._ebOrderViewModel.OrderNumber = "EB-" + randomString;
    // this.checkOrderIdDuplication();
    console.log("random", randomString);
  }
  public saving:boolean=false;
  onSubmitOrder() {
    if(this._ebOrderViewModel.IsReturn == undefined){
        this._ebOrderViewModel.IsReturn = false;
    }
    let ebOrder: any = Object.assign({}, this._ebOrderViewModel);
    this.saving = true;
    this._ebService.SaveUpdateEbOrders(ebOrder).toPromise().then(() => {
      this.saving = false;
      this._notificationService.notify("success", "Save Successfully");
    }).catch(err =>{
      this.saving = false;
      this._notificationService.notify("danger", err.error);
    });
  }
}
