export class StockInViewModel {
  public PackageNo: string;
  public Distributor: string;
  public Manufacturer: string;
  public Batch: string;
  public ManufacturingDate: Date;
  public ExpiryDate: Date;
  public RecevingDate: Date;
  public MedicineId: ItemViewModel;
  public SourceId: ItemViewModel;
  public HFId: ItemViewModel;
  public UomId: any;
  public Quantity: number;
}

export class ItemViewModel {
  constructor(id?: number, name?: string) {
    this.Id = id;
    this.Name = name;
  }
  public Id: number;
  public Name: string;
}

export class StockAllocateViewModel {
  public MedicineId: ItemViewModel;
  public DeptId: ItemViewModel;
  public Batch: ItemViewModel;
  public Quantity: number;
  public Comments: string;
}

export class StockTransferViewModel {
  public MedicineId: ItemViewModel;
  public Batch: ItemViewModel;
  public Quantity: number;
  public Comments: string;
  public CreatedBy: string;
  public HealthFacilityTo: ItemViewModel;
}

export class StockDemandViewModel {
  public StockDemandMaster_Id: number;
  public Medicine_Id: number;
  public Name: string;
  public Quantity: number;
}
export class StockDemandMasterViewModel {
  public Year: number;
  public HfmisCodeId: string;
  public HfmisCode: string;
  public FacilityName: string;
  public stockDemands: StockDemandViewModel[];
}
