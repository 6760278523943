import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { NotificationService } from '../../../_services/notification.service';
import { EmployeeServices } from '../../employee/employee-services';
import { EmployeeStatusViewModel } from '../setting.class';
import { SettingServices } from '../setting.services';

@Component({
  selector: 'app-employee-status',
  templateUrl: './employee-status.component.html',
  styleUrls: ['./employee-status.component.scss']
})
export class EmployeeStatusComponent implements OnInit {
public EmployeeStatusViewModel = new EmployeeStatusViewModel();
employeeStatusList:Array<any> =[];
public openDilog = false;
public openDilogConfirmation = false;
loading:boolean = false;
public kGrid: KGridHelper = new KGridHelper();
  constructor(public _notificationService: NotificationService,
    private _settingsService: SettingServices) { }

  ngOnInit() {
    this.initializeProps();
    this.getEmployeeStatus();
  }
  private initializeProps() {
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }
  public getEmployeeStatus() {
    try {
      this.kGrid.loading = true;
      this._settingsService.getEmployeeStatus().subscribe((res: any) => {
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      });
    } catch (error) {
      this.kGrid.loading = false;
      this._notificationService.notify('danger', 'Something went wrong');
      console.log(error)
    }
  }
  onSubmit() {
    this.loading = true;
    let employeeStatus: any = Object.assign({}, this.EmployeeStatusViewModel);
    this._settingsService.SaveEmployeStatus(employeeStatus).subscribe((res: any) => {
      this.loading = false;
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.getEmployeeStatus();
      this.closeStatusModal();
    }, (err) => {
      this.loading = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }
  public OpenDialogForEdit(item) {
    console.log(item);
    // this.ve.Id = id;
    this.EmployeeStatusViewModel = item;
    this.openDilog = true;
  }
  public RemoveEmployeeStatus(e) {
    this.EmployeeStatusViewModel = e;
    this.openDilogConfirmation = true;
  }
  public ConfirmDelete() {
    this.loading = true;
    this._settingsService.DeleteEmployeStatus(this.EmployeeStatusViewModel).subscribe((res: any) => {
      this.loading = false;
      this._notificationService.notify('success', 'Delete Successful');
      this.getEmployeeStatus();
      this.openDilogConfirmation = false;
    }, err => {
      this.loading = false;
      this._notificationService.notify('danger', err.error);

    });
  }
  public closeDelete() {
    this.openDilogConfirmation = false;
  }
  public OpenEmployeeStatusModal() {
    this.openDilog = true;
  }

  public closeStatusModal() {
    this.EmployeeStatusViewModel = new EmployeeStatusViewModel();
    this.openDilog = false;
  }
  public duplicateFlag:boolean =false;
  isDuplicate:boolean = false;
  checkEmployeeStausDuplication(){
    this.duplicateFlag = false;
  for(let i of this.kGrid.data){
    
      if(i.Name == this.EmployeeStatusViewModel.Name){
      console.log('fid duplicate ');
      this.duplicateFlag =true;
    }
  }
  if(this.duplicateFlag){
    this.isDuplicate =true;
  }
  else{
    this.isDuplicate = false;
  }
  }
  isDuplicateOrder(flag){
return flag;
  }
}
