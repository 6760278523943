import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { QuotationSalesPersonModel } from '../setting.class';
import { NotificationService } from '../../../_services/notification.service';
import { SettingServices } from '../setting.services';
import { LocalService } from '../../../_services/local.service';
import { ExportExcelService } from '../../../_services/export-excel.service';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-sales-person',
  templateUrl: './sales-person.component.html',
  styleUrls: ['./sales-person.component.scss']
})
export class SalesPersonComponent implements OnInit {

  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  public kGrid: KGridHelper = new KGridHelper();
  public deleting = false;
  public deletedDocId: number;
  public openDilog = false;
  public searchTerm = '';
  public openDilogConfirmation = false;
  public isExcelExport:boolean = false;
  public salePerson = new QuotationSalesPersonModel();
  constructor(public _notificationService: NotificationService,
    public _settingService: SettingServices,
    public _localService: LocalService,
    private _exportToExcelService: ExportExcelService) {
  }

  ngOnInit() {
    this.initializeProps();
    this.getSalesPerson();
  }

  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }


  public getSalesPerson() {
    this._settingService.getSalesPerson(this.kGrid.skip, this.kGrid.pageSize, this.kGrid.searchQuery, this.kGrid.hfId, this.kGrid.geoLvlCode, this.kGrid.hfType, this.kGrid.stockId, this.searchTerm).subscribe((res: any) => {
      debugger;
      if (res) {
        console.log(res);
        this.kGrid.data = [];
        this.kGrid.data = res.res;
        this.kGrid.totalRecords = res.tc;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }
    }, err => {
      debugger;

      this._notificationService.notify("danger", err.msg);

    });
  }
  public OpenDileogForSalePerson() {
    this.openDilog = true;
  }
  public close(status) {

    this.openDilog = false;
  }
  public closeCat(status) {
    this.salePerson = new QuotationSalesPersonModel();
    this.openDilog = false;
    this.openDilogConfirmation = false;

  }
 
  public onSubmit(status) {

    this.openDilog = false;
     this._settingService.saveSalesPerson(this.salePerson).subscribe((res: any) => {

      this._notificationService.notify("success", "Category Add Successfully");
       this.getSalesPerson();

     }, err => {

       this._notificationService.notify("danger", err.msg);

     });
     this.salePerson = new QuotationSalesPersonModel();

  }


  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
       this._settingService.getSalesPerson(this.kGrid.skip, this.kGrid.pageSize, this.kGrid.searchQuery, this.kGrid.hfId, this.kGrid.geoLvlCode, this.kGrid.hfType, this.kGrid.stockId,this.searchTerm).subscribe((res: any) => {
         if (res) {
           console.log(res);
           this.kGrid.data = [];
           this.kGrid.data = res.res;
           this.kGrid.totalRecords = res.tc;
           this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
           this.kGrid.loading = false;
         }
         }, (error) => {
         console.log(error);
       });
    } else {
      this.ngOnInit();
    }
  }

  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    this.getSalesPerson();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    this.sortData();
  }
  private sortData() {
    this.kGrid.gridView = {
      data: orderBy(this.kGrid.data, this.kGrid.sort),
      total: this.kGrid.totalRecords
    };
  }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    this.getSalesPerson();
  }
  public RemoveSalesPerson(e) {
    this.deletedDocId = e;
    this.openDilogConfirmation = true;
  }

  public ConfirmDelete() {
     this._settingService.removeSalePerson(this.deletedDocId).subscribe((res: any) => {
       this.deletedDocId = null;
       this.openDilogConfirmation = false;
       this._notificationService.notify("success", "Delete Successful");

       this.getSalesPerson();

     }, err => {
       this._notificationService.notify("danger", err.error);

     });
  }

  
  exportToExcel(){
    this.isExcelExport = true;
    let excelArr=[];
  
    for(let i =0; i < this.kGrid.data.length; i++) {
      let obj ={
        Name:this.kGrid.data[i].Name,
        Phone:this.kGrid.data[i].Phone,
        Email:this.kGrid.data[i].Email,
        Description:this.kGrid.data[i].Description,
        CreateDate:this.kGrid.data[i].CreateDate
      }
      excelArr.push(obj);
    }
    this.isExcelExport = false;
    this._exportToExcelService.exportAsExcelFile(excelArr,'salesPerson');
    console.log('new array',excelArr);
}

public EditSalesPerson(x: any) {
  this.openDilog = true;

  let data: any = Object.assign({}, x);
   this.salePerson = data;
  // console.log(this.catlog);

}

}
