import { Component, OnInit } from '@angular/core';
import { InsuranceExpense } from '../setting.class';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationService } from '../../../_services/notification.service';
import { CoustomerServices } from '../../coustomer/coustome-services';
import { EmployeeSalaryServices } from '../../emp-salary/emp-salary-services';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../store/store.services';
import { SettingServices } from '../setting.services';
import { InsuranceexpenseVieModel } from '../../employee/employee-class';
import { LocalService } from '../../../_services/local.service';

@Component({
  selector: 'app-Insurance-expense-defining',
  templateUrl: './Insurance-expense-defining.component.html',
})
export class InsuranceExpenseDefiningComponent implements OnInit {
  public openDilog = false;
  public openDilogCat = false;
  private InsuranceExpenseList: any[] = [];

  public kGrid: KGridHelper = new KGridHelper();
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  public expAccountList: any[] = [];
  public openDilogConfirmation = false;
  public ve: InsuranceExpense = new InsuranceExpense();
  constructor(public _notificationService: NotificationService,
    public _storeService: StoreService,
    public _settingService: SettingServices,
    private activatedRoute: ActivatedRoute,
    private _localService: LocalService) { }

  ngOnInit() {
    this.initializeProps();
    this.getInsuranceExpenseHeadList();
  }
  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }

  onSubmit() {
    this._settingService.saveInsuranceExpenseHead(this.ve).subscribe((res: any) => {
      this.openDilog = false;
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.getInsuranceExpenseHeadList();
      this.ve = new InsuranceExpense()
    }, (err) => {
      this.openDilog = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }

  OnExpenseAccountChange(event) {
    console.log(event);

    this.ve.Fk_AccountDefination  = event;
  }

  public getInsuranceExpenseHeadList() {
    this.kGrid.loading = true;
    this._settingService.getInsuranceExpenseHeadList().subscribe((res: any) => {
      console.log(res);
      this.InsuranceExpenseList = res;
      this.kGrid.data = [];
      this.kGrid.data = res;
      this.kGrid.totalRecords = this.kGrid.data.length;
      this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
      this.kGrid.loading = false;
    }, (err) => {
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }

  public OpenDialogForEdit(item) {
    console.log(item);
    // this.ve.Id = id;
    this.ve = item;
    this.openDilog = true;
    this.getAccount(' ', 'Exp');
    this.handleSearchEvents();
  }


  public RemoveInsuranceExpenseHead(e) {
    this.ve = e;
    this.openDilogConfirmation = true;
  }

  public ConfirmDelete() {
    this._settingService.removeInsuranceExpenseHead(this.ve).subscribe((res: any) => {
      this.ve = null;
      this.openDilogConfirmation = false;
      this._notificationService.notify('success', 'Delete Successful');
      this.getInsuranceExpenseHeadList();
    }, err => {
      this._notificationService.notify('danger', err.error);

    });
  }

  public closeDelete() {
    this.openDilogConfirmation = false;
  }

  public OpenDileogForCategory() {
    this.openDilog = true;
    this.getAccount(null, 'Exp');
    this.handleSearchEvents();
  }

  public close(status) {
    this.openDilog = false;
  }

  public getAccount(x: string, event: string) {
    debugger;
    this._storeService.getAccountBySearch(x, event).subscribe((res: any) => {
      if (res) {
        if (event == 'Exp')
          this.expAccountList = res;
      }
    }, err => {
      this._notificationService.notify("danger", err.msg);

    });
  }

  public handleSearchEvents() {
    this.searchSubcription = this.searchEvent.pipe(
      debounceTime(400)).subscribe((x: any) => {
        console.log(x);
        if (x) {
          debugger;
          if (x.filter == "FK_Exp_Acc") {
            this.getAccount(x.event, "Exp");
          }
        }
      });
  }
  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    // this.getCategory();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    // this.sortData();
  }
  // private sortData() {
  //   this.kGrid.gridView = {
  //     data: orderBy(this.kGrid.data, this.kGrid.sort),
  //     total: this.kGrid.totalRecords
  //   };
  // }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    // this.getCategory();
  }
}
