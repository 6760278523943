import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../_services/authentication.service';
import { LocalService } from '../../_services/local.service';
import { Config } from "../../_helpers/config.class";
@Injectable({
  providedIn: 'root'
})
export class EbService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(
    private http: HttpClient,
    _authenticationService: AuthenticationService,
    private _localService: LocalService
  ) {
    this.headers.set("Content-Type", "application/x-www-form-urlencoded");
    this.headers.set(
      "Authorization",
      "Bearer " + _authenticationService.getToken()
    );
  }
  GetEbCustomersList(){
    return this.http.get(`${Config.getControllerUrl("EBOrders", "GetEbCustomersList")}`);
  }
  GetEBOrderListt(){
    return this.http.get(`${Config.getControllerUrl("EBOrders", "GetEBOrderListt")}`);
  }
  public GetEBOrderListtPaginate(
    skip: number,
    pageSize: number,
    query: string,
    hfId: string,
    geoLvlCode: string,
    hfType: string,
    stockId: number,
    searchTerm: string
  ) {
    debugger;
    return this.http.post(
      `${Config.getControllerUrl("EBOrders", "GetEBOrderListtPaginate")}`,
      {
        Skip: skip,
        PageSize: pageSize,
        Query: query,
        HfId: hfId,
        GeoLvlCode: geoLvlCode,
        HfType: hfType,
        StockId: stockId,
        Search: searchTerm,
      }
    );
  }
  SaveUpdateEbOrders(object){
    let formData = new FormData();
    // formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(object));
    return this.http.post(`${Config.getControllerUrl("EBOrders", "SaveUpdateEbOrders")}`,formData);
  }
  GetInstalmentsByEBId(id){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetInstalmentsByEBId")}`,{Id:id});
  }
  GetUnPaidEBOrdersPOSBank(toDate){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetUnPaidEBOrdersPOSBank")}`,{ToDate:toDate});
  }
  GetUnPaidEBOrdersCashByEmployeeId(obj){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetUnPaidEBOrdersCashByEmployeeId")}`,{Id:obj.Id,ToDate:obj.ToDate});
  }
  GetUnPaidEBOrdersPOSByEmployeeId(obj){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetUnPaidEBOrdersPOSByEmployeeId")}`,{Id:obj.Id,ToDate:obj.ToDate});
  }
  GetUnPaidCashGroupByEmployee(ToDate){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetUnPaidCashGroupByEmployee")}`,{ToDate:ToDate});
  }
  GetUnPaidPOSGroupByEmployee(ToDate){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetUnPaidPOSGroupByEmployee")}`,{ToDate:ToDate});
  }
  SaveEbBulkReturnOrders(obj:any){
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl("EBOrders", "SaveEbBulkReturnOrders")}`,formData);
  }
  AddBulkEBCash(obj:any){
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl("EBOrders", "AddBulkEBCash")}`,formData);
  }
  AddBulkEBPOS(obj:any){
    let formData = new FormData();
    formData.append("file", obj.file);
    formData.append("Obj", JSON.stringify(obj));
    return this.http.post(`${Config.getControllerUrl("EBOrders", "AddBulkEBPOS")}`,formData);
  }
  GetEBSettlement(){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetEBSettlement")}`,{});
  }
  GetEBTransactionDetail(id){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetEBTransactionDetail")}`,{Id:id});
  }
  GetInstalmentListBySettlement(id){
    return this.http.post(`${Config.getControllerUrl("EBOrders", "GetInstalmentListBySettlement")}`,{Id:id});
  }
}
