﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from './cookie.service';
import { LocalService } from './local.service';
import { UserNav, Nav } from '../_models/nav.class';
import { RootService } from './root.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../_helpers/config.class';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private _rootService: RootService, private router: Router, private _localService: LocalService, private _cookieService: CookieService, private http: HttpClient) { }

  public getUser() {
    return this._localService.get('ussr');
  }

  public getCom() {
    return this._localService.get('com');
  }

  public isUserStampValid(user) {
      return this.http.post(`${Config.getControllerUrl('Account', 'ValidateUserStamp')}`, { User: user.Id, Stamp: user.Stamp, ClientId:user.clientId });
  }

  public isDisable(id,status) {
    return this.http.post(`${Config.getControllerUrl('Accounts', 'EmployeDesable')}`, { Id: id, Bit: status });
}

  // export class SecurityStampValidationModel{
  //   public User : string;
  //   public Stamp : string;
  //   }


  public setUser(_: any): boolean {
    // tslint:disable-next-line: no-debugger
    debugger;
    let user = JSON.parse(_.user),
      com = JSON.parse(_.com),
      nav = _.nav,
      token = _.access_token;
    if (token !== '') {
      console.log('gonna clear the cookie if exists');
      this._cookieService.deleteAndSetCookie('ussr', token);
      // user.HfmisCode = (user.HfmisCode ?
      //   user.HfmisCode : user.TehsilCode ?
      //     user.TehsilCode : user.DistrictCode ?
      //       user.DistrictCode : user.DivisionCode ?
      // user.DivisionCode : '0');
      this._localService.set('ussr', user);
      this._localService.set('ussrNvd', nav);
      this._localService.set('com', com);
      return true;
    } else {
      console.log('logout');
      this.logout();
      return false;
    }
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public getNav() {
    console.log('getting the nav');

    let realNav: Nav[] = [];
    let serverNav: any = this._localService.get('ussrNvd');
    let parsedNav: any[] = JSON.parse(serverNav);
    if (parsedNav !== undefined && parsedNav.length > 0) {

      parsedNav.forEach(n => {
        let nav: Nav = new Nav();
        nav.name = n.Name;
        nav.url = '/' + n.Url;
        nav.icon = n.Icon;
        nav.children = n.children.length > 0 ? this.getchildNav(n.children) : null;
        realNav.push(nav);
      });
    }
    return realNav;
  }
  getchildNav(n: any) {
    let childNav: Nav[] = [];
    n.forEach(c => {
      let cn: Nav = new Nav();
      cn.name = c.Name;
      cn.url = '/' + c.Url;
      cn.icon = c.Icon;
      cn.children = c.children.length > 0 ? this.getchildNav(c.children) : null;
      childNav.push(cn);
    });
    return childNav;
  }

  logout() {
    this._cookieService.deleteCookie('ussr');
    this._cookieService.deleteCookie('ussrNvd');
    this._localService.remove('ussr');
    this._localService.remove('ussrNvd');
    this._localService.remove('com');
    this.router.navigate(['/account/login']);
  }

  public getUserHfmisCode() {
    return this.getUser().HfmisCode;
  }
  public getUserLevel() {
    let hfmisCode: string = this.getUserHfmisCode();

    return hfmisCode.length == 1 ? 'Province' : hfmisCode.length == 3 ? 'Division' :
      hfmisCode.length == 6
        ? 'District' : hfmisCode.length == 9 ? 'Tehsil' : '';
  }

  public getUserLevelName() {
    let user = this.getUser();
    let hfmisCode: string = user.HfmisCode;
    switch (hfmisCode.length) {
      case 1:
        return 'Punjab';
      /*   case 3:
            this._mainService.getDivisionsByCode(hfmisCode).subscribe((data) => {
                this.divisions = data;
                if (data && data.length > 0) {
                    return data[0].Name;
                }
            }, (err) => this.handleError(err));
            return 'Punjab';
        case 6:
            this._mainService.getDistrictsByCode(hfmisCode).subscribe((data) => {
                if (data && data.length > 0) {
                    return data[0].Name;
                }
            }, (err) => this.handleError(err));
            return 'Punjab';
        case 9:
            this._mainService.getTehsilsByCode(hfmisCode).subscribe((data) => {
                if (data && data.length > 0) {
                    return data[0].Name;
                }
            }, (err) => this.handleError(err));
            return 'Punjab';
        case 19:
            return 'Punjab'; */
      default:
        break;
    }
  }
}
