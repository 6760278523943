import { Component, OnInit } from '@angular/core';
import { Department, Company, HeaderFooter } from '../setting.class';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationService } from '../../../_services/notification.service';
import { CoustomerServices } from '../../coustomer/coustome-services';
import { EmployeeSalaryServices } from '../../emp-salary/emp-salary-services';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../store/store.services';
import { SettingServices } from '../setting.services';
import { VisaexpenseVieModel } from '../../employee/employee-class';
import { LocalService } from '../../../_services/local.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  public openDilog = false;
  public openDilogCat = false;
  public CompList: any = [];
  inputAccounts:Array<any>=[];
  outputAccounts:Array<any> =[];
  public kGrid: KGridHelper = new KGridHelper();
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  public HeaderFooter: HeaderFooter = new HeaderFooter();
  headers:Array<any> =[];
  public expAccountList: any[] = [];
  public openDilogConfirmation = false;
  public comp: Company = new Company();
  constructor(public _notificationService: NotificationService,
    public _storeService: StoreService,
    public _settingService: SettingServices,
    private activatedRoute: ActivatedRoute,
    private _localService: LocalService) { 
      this.comp = new Company();
    }

  ngOnInit() {
     this.initializeProps();
     this.getInputAccount("","Input");
     this.getOutputAccount("","Output");
     this.handleSearchEvents();
    this.getCompList();
    this.getHeaderFooter();
  }

  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }

  public getCompList() {
    debugger
    try {
      this.kGrid.loading = true;
      this._settingService.getCompList().subscribe((res: any) => {
        this.CompList = res;
        console.log(res);
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }, (err) => {
        console.log('er',err);
        this._notificationService.notify('danger', 'Something went wrong');
      });
    } catch (error) {
      console.log(error)
    }

  }
  onselectHeader(){
    
    this.comp.HeaderFooterId = this.headerObject.Id;
  }
  saving:boolean = false;
  headerObject:any ={};
  onSubmit() {
    debugger;
    this.comp.Title ='';
    this.comp.BranchName ='';
    this.comp.AccountNumber ='';
    this.comp.IBAN ='';
    let ct: any = Object.assign({}, this.comp);
    this.saving = true;
    this._settingService.saveComp(ct).subscribe((res: any) => {
      this.openDilog = false;
      debugger
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.getCompList();
      this.saving = false;
      this.comp = new Company()
    }, (err) => {
      
      this.saving = false;
      console.log('save err',err);
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }

  public OpenDialogForEdit(item) {
    console.log(item);
    // this.ve.Id = id;
    this.comp = item;
    this.openDilog = true;

  }


  public ConfirmDelete() {
    debugger
    this.saving = true;
    this._settingService.deleteComp(this.comp).subscribe((res: any) => {
      this.closeDelete();
      this._notificationService.notify('success', 'Delete Successful');
      this.getCompList();
      this.saving = false;
    }, err => {
      this.saving = false;
      this._notificationService.notify('danger', err.error);

    });
  }

  public RemoveDept(e) {
    this.comp = e;
    this.openDilogConfirmation = true;
  }



  public closeDelete() {
    this.comp = new Company();
    this.openDilogConfirmation = false;
  }

  public OpenDileogForCategory() {
    this.openDilog = true;

  }

  public close(status) {
    this.comp = new Company();
    this.openDilog = false;
  }


  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    // this.getCategory();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    // this.sortData();
  }
  // private sortData() {
  //   this.kGrid.gridView = {
  //     data: orderBy(this.kGrid.data, this.kGrid.sort),
  //     total: this.kGrid.totalRecords
  //   };
  // }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    // this.getCategory();
  }

  public handleSearchEvents() {
    this.searchSubcription = this.searchEvent.pipe(
      debounceTime(400)).subscribe((x: any) => {
        console.log(x);
        if (x) {
          
          if (x.filter == "Input-Acc") {
            this.getInputAccount("","Input");
           
          }
          if (x.filter == "Output-Acc") {

            this.getOutputAccount("","Output");
          }

        }
      });
  }

  public getOutputAccount(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("output account", res);
        this.outputAccounts = res;
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  public getInputAccount(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("input account", res);
        this.inputAccounts = res;
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  public getHeaderFooter() {
    this._settingService.GetHeaderFooterList().subscribe((res: any) => {
      if (res) {
        console.log('header footer',res);
       this.headers = res;
      }
    }, err => {
      this._notificationService.notify("danger", err.error.msg);
    });
  }
  accountId:any;
  accountNature:any;
  openDilogPrefConfirmation:boolean = false;
  public openChaneCompanyPrefrence(accountId) {
    debugger;
    this.accountId = accountId;
    this.openDilogPrefConfirmation = true;
  }
  isChangingPreference:boolean = false;
  public ChangeCompaniesPreferance() {
    debugger;
    this.isChangingPreference = true;
    this._settingService.ChangeCompaniesPreferance(this.accountId).toPromise().then((res: any) => {
      this.isChangingPreference = false;
      this._notificationService.notify('success', 'Company Preferance Changed Successful');
      this.closeChaneCompanyPrefrence();
      this.getCompList();
    }).catch(err =>{
        console.log('err',err);
        this.isChangingPreference = false;
        this._notificationService.notify('danger', err.error);
    });
  }
  public closeChaneCompanyPrefrence() {
    this.openDilogPrefConfirmation = false;
  }
}
