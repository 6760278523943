import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { StoreService } from '../../store/store.services';
import { EmployeeServices } from '../../employee/employee-services';
import { NotificationService } from '../../../_services/notification.service';
import { CoustomerServices } from '../../coustomer/coustome-services';
import { Router } from '@angular/router';
import { EbService } from '../eb-service.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-bulk-eb-pos-list',
  templateUrl: './bulk-eb-pos-list.component.html',
  styleUrls: ['./bulk-eb-pos-list.component.scss']
})
export class BulkEbPosListComponent implements OnInit {
  public kGrid: KGridHelper = new KGridHelper();
  public openDilouge:boolean = false;
  viewObj:any={};
  constructor(
    private _storeService: StoreService,
    private _employeeService: EmployeeServices,
    private _notificationService: NotificationService,
    private _customerService: CoustomerServices,
    private router: Router,
    private _ebService: EbService
  ) {
    
  }

  ngOnInit() {
    this.initializeProps();
    this.GetEBSettlement();
  }
  GetEBSettlement(){
    this.kGrid.loading = true;
    this._ebService.GetEBSettlement().subscribe(
      (response: any) => {
        console.log("bulk pos list", response);
        this.kGrid.data = [];
        this.kGrid.data = response;
        console.log("response data", this.kGrid.data);
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = {
          data: this.kGrid.data,
          total: this.kGrid.totalRecords,
        };
        this.kGrid.loading = false;
        console.log(this.kGrid.gridView);
      },error =>{
        this.kGrid.loading = false;
      });
  }
  ///////////////////////////Grid Section////////////////////
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == "asd") {
      return;
    }
    this.kGrid.sort = sort;
    this.sortData();
  }

  private sortData() {
    this.kGrid.gridView = {
      data: orderBy(this.kGrid.data, this.kGrid.sort),
      total: this.kGrid.totalRecords,
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
  }

  private handleError(err: any) {
    this.kGrid.loading = false;
    if (err.status == 403) {
    }
  }

  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    // this.GetEBOrderListt();
  }
  private initializeProps() {
    // this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }
  openViewPage(object){
    this.viewObj = object;
this.openDilouge = true;
  }
  closeViewPage(){
    this.openDilouge = false;;
  }
}
