import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';
import { HttpClient } from '@angular/common/http';
import { Config } from '../_helpers/config.class';
@Injectable({
  providedIn: 'root'
})
export class RootService {
  constructor(private http: HttpClient, private _cookieService: CookieService) { }
  public search(query: string) {
    return this.http.post<any>(`${Config.getControllerUrl('Root', 'Search')}`, { Query: query });
  }

  public getMultipelHF(tehList: string[]) {
    return this.http.post<any>(`${Config.getControllerUrl('Root', 'GetMultipelHF')}`, { TehList: tehList });
  }
  public searchHealthFacilities(query: string) {
    return this.http.post(`${Config.getControllerUrl('Root', 'SearchHealthFacilities')}`, { Query: query });
  }
  public getDivisions(hfmisCode: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetDivisions')}/${hfmisCode}`);
  }
  public getDistricts(hfmisCode: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetDistricts')}/${hfmisCode}`);
  }
  public getDistrictsOnChang(divCode: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetDistrictsOnChang')}/${divCode}`);
  }
  public getTehsils(hfmisCode: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetTehsils')}/${hfmisCode}`);
  }
  public getTehsilsOnChang(disCode: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetTehsilsOnChang')}/${disCode}`);
  }
  public getMedList(search: string) {
    return this.http.post(`${Config.getControllerUrl('Root', 'GetMedList')}`, { Search: search });
  }
  public getStockDtl(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('StockApi', 'GetStockDetail')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }

  public getDepList() {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetDepList')}`);
  }



  public getUomList() {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetUomList')}`);
  }
  public getSourceList() {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetSourceList')}`);
  }

  public getBhuRhcList(search: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetBhuRhcList')}/${search}`);
  }

  public getHfList(geoLvl: string, hfTypeCode: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetHFList')}/${geoLvl}/${hfTypeCode}`);
  }


  public getHFTypes() {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetTypes')}`);
  }
  public getHFCategories() {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetCategories')}`);
  }
  public getHFCategory(id) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetCategory')}/${id}`);
  }
  public getEmploymentModes() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetEmploymentModes`);
  }
  public getDepartments() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis')}/GetDepartments`);
  }
  public getDepartmentsHealth() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetDepartmentsHealth`);
  }
  public getDomiciles() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis')}/GetDomiciles`);
  }
  public getLanguages() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis')}/GetLanguages`);
  }
  public getSpecializations() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis')}/GetSpecializations`);
  }
  public getQualifications() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis')}/GetQualifications`);
  }
  public getProfileStatuses() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetProfileStatuses`);
  }
  public getCadres() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis', 'GetCadres')}`);
  }
  public getPostTypes() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetPostTypes`);
  }
  public getApplicationTypes() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetApplicationTypes`);
  }

  public getApplicationStatus() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetApplicationStatus`);
  }
  public getOrderTypes() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetOrderTypes`);
  }
  public getLeaveTypes() {
    return this.http.get(`${Config.getControllerUrl('Root')}/GetLeaveTypes`);
  }
  public getDesignations() {
    return this.http.get(`${Config.getControllerUrl('BaseDataHrmis', 'GetDesignations')}`);
  }
  public getHealthFacilities(hfmisCode: string, deptId?: number) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetHealthFacilities')}/${hfmisCode}/${deptId}`);
  }
  public getPandSOfficers(type: string) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetPandSOfficers')}/${type}`);
  }
  public getApplicationDocuments(typeId: number) {
    return this.http.get(`${Config.getControllerUrl('Root', 'GetApplicationDocuments')}/${typeId}`);
  }
  public calcDate(fromDate: string, toDate: string, totalDays: number) {
    return this.http.get(`${Config.getControllerUrl('Root', 'CalcDate')}/${fromDate}/${toDate}/${totalDays}`);
  }
  public getVacancyReport(hfmisCode: string) {
    return this.http.get<any>(`${Config.getControllerUrl('Vacancy', 'Report')}/${hfmisCode}`);
  }
  public getAnyProfile(cnic: string) {
    return this.http.get(`${Config.getControllerUrl('Profile', 'GetAnyProfile')}/${cnic}`);
  }
}
