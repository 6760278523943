import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EbService } from '../eb-service.service';

@Component({
  selector: 'app-eb-pos-balance-sheet-detail',
  templateUrl: './eb-pos-balance-sheet-detail.component.html',
  styleUrls: ['./eb-pos-balance-sheet-detail.component.scss']
})
export class EbPosBalanceSheetDetailComponent implements OnInit {
  id:any;
  toDate:Date;
  fetchedEbOrders:Array<any>=[];
  fetchSpinner:boolean = false;
  OrderAmount:number=0;
  EBCharges:number=0;
  PaidAmountToTasker:number=0;
  constructor(private activatedRoute: ActivatedRoute, private ebService: EbService) {
    this.activatedRoute.queryParams.subscribe(params =>{
      this.toDate =params['toDate'];
      this.toDate = new Date(this.toDate);
      this.id = params['id'];
    });
   }

  ngOnInit() {
    this.GetUnPaidEBOrdersPOSByEmployeeId();
  }
  GetUnPaidEBOrdersPOSByEmployeeId(){
    this.fetchSpinner = true;
    
   let fetchObj ={
     Id:this.id,
     ToDate:this.toDate
   }
    this.ebService
      .GetUnPaidEBOrdersPOSByEmployeeId(fetchObj)
      .subscribe((ebOrders:any) => {
        console.log("fetched list", ebOrders);
        this.fetchedEbOrders = ebOrders.bulkPaymentList;
        this.EBCharges =0;
    this.OrderAmount =0;
    this.PaidAmountToTasker =0;
        this.fetchedEbOrders.forEach(x =>{
          this.OrderAmount +=x.OrderAmount;
          this.EBCharges +=x.EBCharges
          this.PaidAmountToTasker +=x.PaidAmountToTasker
        });
        this.fetchSpinner = false;
      },error => {
        this.fetchSpinner = false;
      });
  }
}
