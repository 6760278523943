import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EbService } from '../eb-service.service';

@Component({
  selector: 'app-eb-cash-balance-sheet-detail',
  templateUrl: './eb-cash-balance-sheet-detail.component.html',
  styleUrls: ['./eb-cash-balance-sheet-detail.component.scss']
})
export class EbCashBalanceSheetDetailComponent implements OnInit {
id:any;
toDate:Date;
fetchedEbOrders:Array<any>=[];
fetchSpinner:boolean = false;
OrderAmount:number=0;
EBCharges:number=0;
PaidAmountByTasker:number=0;
  constructor(private activatedRoute: ActivatedRoute, private ebService: EbService) {
    this.activatedRoute.queryParams.subscribe(params =>{
      this.toDate =params['toDate'];
      this.toDate = new Date(this.toDate);
      this.id = params['id'];
    });
   }

  ngOnInit() {
    this.GetUnPaidEBOrdersCashByEmployeeId();
    
  }
  GetUnPaidEBOrdersCashByEmployeeId(){
    this.fetchSpinner = true;
    
   let fetchObj ={
     Id:this.id,
     ToDate:this.toDate
   }
    this.ebService
      .GetUnPaidEBOrdersCashByEmployeeId(fetchObj)
      .subscribe((ebOrders:any) => {
        console.log("fetched list", ebOrders);
        this.fetchedEbOrders = ebOrders.bulkPaymentList;
        this.EBCharges =0;
    this.OrderAmount =0;
    this.PaidAmountByTasker =0;
        this.fetchedEbOrders.forEach(x =>{
          this.OrderAmount +=x.OrderAmount;
          this.EBCharges +=x.EBCharges
          this.PaidAmountByTasker +=x.PaidAmountByTasker
        });
        this.fetchSpinner = false;
      },error => {
        this.fetchSpinner = false;
      });
  }
}
