import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../_services/authentication.service';
import { Config } from '../../_helpers/config.class';
import { ItemViewModel } from '../store/store-model';
import { LocalService } from '../../_services/local.service';

@Injectable()
export class ReportingService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, _authenticationService: AuthenticationService, private _localService: LocalService) {
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
    this.headers.set('Authorization', 'Bearer ' + _authenticationService.getToken())
  }
  public getStockReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetStockReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getInventoryReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetInventoryReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getInventoryStockReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetInventoryStockReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }


  public getAssingnRetunReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetAssingnRetunReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getRentedRecivedRetunReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetRentedRecivedRetunReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getTraficFIneReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetTraficFIneReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getOtherExpReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetOtherExpReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getEmployeCurrentAssigning(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, accId: number, toDate: Date) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetEmployeCurrentAssigning')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, AccId: accId, ToDatee: toDate });
  }
  public getEmployeGenericReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, accId: number, toDate: Date, fromDate:Date) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetEmployeeLadgerReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, AccId: accId, ToDatee: toDate , FromDatee: fromDate});
  }

  public getAssingedEmpReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, accId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetAssingedEmpReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, AccId: accId });
  }

  public getAssingedEmp(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, accId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('Coustomer', 'GetAssingedEmp')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, AccId: accId , Search: searchTerm});
  }
  public getAssinged4Pl(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, accId: number, searchTerm: string) {
    return this.http.post(`${Config.getControllerUrl('SupplierContract', 'GetAssingedEmp')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, AccId: accId , Search: searchTerm});
  }

  public getUnAssingedEmpReport(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetUnAssingedEmpReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getContrectList(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, hfType: string, stockId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetContrectList')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, HfType: hfType, StockId: stockId });
  }
  public getLadgerReport(skip: number, pageSize: number, query: string, hfId: string, fd: Date, td: Date, accId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetLadgerReport')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, FromDate: fd, Todate: td, AccId: accId });
  }
  public getDayBookReport(skip: number, pageSize: number, query: string, td: Date, fd: Date) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetDayBookReport')}`, { Skip: skip, PageSize: pageSize, Query: query, FromDate: fd, Todate: td });
  }
  public getCashReceving(skip: number, pageSize: number, query: string, td: Date, fd: Date, empId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetCashReceving')}`, { Skip: skip, PageSize: pageSize, Query: query, FromDate: fd, Todate: td, EmpId: empId });
  }
  public getHeadChartReport() {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetHeadChartReport')}`, null);
  }

  public getCashContrectWiseReceving(skip: number, pageSize: number, query: string, hfId: string, geoLvlCode: string, empId: number) {
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetCashContrectWiseReceving')}`, { Skip: skip, PageSize: pageSize, Query: query, HfId: hfId, GeoLvlCode: geoLvlCode, EmpId: empId });
  }

  public GetEmployePayslip(obj: any) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetEmployePayslip')}`, obj);
  }

  public getIncomeStatement(obj: any) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetIncomeStatement')}`, obj);
  }

  public getBalanceSheet(obj: any) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetBalanceSheet')}`, obj);
  }
  public getBalanceSheetv1(obj: any) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetBalanceSheetv1')}`, obj);
  }

  public getTrialBalance(obj: any) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetTrialBalance')}`, obj);

  }
  public getExpenseReport(obj: ExpenseFiltersViewModel) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetExpenseReport')}`, obj);

  }


  public getFiltersForExpenseReport(obj: ExpenseFiltersViewModel) {
    this._localService.dateTransFuckDatesInObject(obj);
    return this.http.post(`${Config.getControllerUrl('Reporting', 'GetFiltersForExpenseReport')}`, obj);

  }
  public GetEmployeUnPaidCODReport(toDate) {
    
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetEmployeUnPaidCODReport')}`, {ToDate:toDate});

  }
  public GetEmployeUnPaidCODDetailReport(toDate,id,searchTerm?) {
    
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetEmployeUnPaidCODDetailReport')}`, {Id:id,ToDate:toDate,Search:searchTerm});

  }
  public GetUnPaidCODGroupByEmployee(toDate) {
    return this.http.post(`${Config.getControllerUrl('OrderCOD', 'GetUnPaidCODGroupByEmployee')}`, {ToDate:toDate});
  }
}

export class ExpenseFiltersViewModel {
  public color: string;
  public brand: string;
  public BikeModel: string;
  public enginge: string;
  public city: string;
  public Geradge: string;
  public StartDate?: Date;
  public EndDate?: Date;
  public Item: ItemViewModel;
  public Suppliar: ItemViewModel;
  public Customer: ItemViewModel;
  public Expensehead: ItemViewModel;
}

