import { Component, OnInit } from '@angular/core';
import { SettingServices } from '../../setting.services';
import { HeaderFooter } from '../../setting.class';
import { NotificationService } from '../../../../_services/notification.service';

@Component({
  selector: 'app-header-print',
  templateUrl: './header-print.component.html',

})
export class HeaderPrintComponent implements OnInit {

  public obj: HeaderFooter;
  public saving = false;
  constructor(
    private settingServices: SettingServices,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.obj = new HeaderFooter();
    this.getHeaderFooter();
  }
  public onSubmit() {
    this.settingServices.saveHeaderFootrer(this.obj).subscribe((res: any) => {
      if (res) {
        this.obj = res;
      }
    }, err => {

      this._notificationService.notify("danger", err.error.msg);

    });
  }
  public getHeaderFooter() {
    this.settingServices.getHeaderFooter().subscribe((res: any) => {
      if (res) {
        this.obj = res;
      }
    }, err => {
      this._notificationService.notify("danger", err.error);
    });
  }
}
