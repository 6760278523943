import { Component, OnInit } from '@angular/core';
import { FormRestrictionDefiningViewModel, E_Valid_Form } from '../setting.class';
import { Subject } from 'rxjs';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationService } from '../../../_services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from '../../../_services/local.service';
import { ClientService } from '../../clients/clients.service';
import { ItemViewModel } from '../../store/store-model';

@Component({
  selector: 'app-form-restrictions',
  templateUrl: './form-restrictions.component.html',
  styleUrls: ['./form-restrictions.component.scss']
})
export class FormRestrictionsComponent implements OnInit {
  public openDilog = false;
  public openDilogCat = false;
  public FormList: any = [];
  public SelectedFormName : any = [];
  public kGrid: KGridHelper = new KGridHelper();
  public searchEvent = new Subject<any>();
  public openDilogConfirmation = false;
  public FormVm: FormRestrictionDefiningViewModel = new FormRestrictionDefiningViewModel();
  public FormsNamesList: E_Valid_Form = new E_Valid_Form();
  public maxDate = new Date();
  constructor(
    public _notificationService: NotificationService,
    public _clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private _localService: LocalService) { }

    public FormNames:E_Valid_Form[] = [
      {Id: 1, Name: 'Item Assigning'},
      {Id: 2, Name: 'Vehicle Rent'},
      {Id: 3, Name: 'Vehicle Rent Inv'},
      {Id: 4, Name: 'Item Depreciation '},
      {Id: 5, Name: 'Purchase'},
      {Id: 6, Name: 'Purchase Installment'},
      {Id: 7, Name: 'Sale'},
      {Id: 8, Name: 'Sale Installment '},
      {Id: 9, Name: 'Daybook'},
      {Id: 10, Name: 'Journal Voucher'},
      {Id: 11, Name: 'Manage Cheque '},
      {Id: 12, Name: 'Employee Assiging'},
      {Id: 13, Name: 'Security Deposits'},
      {Id: 14, Name: 'Salary Commission'},
      {Id: 15, Name: 'Salary Proceed to Payment '},
      {Id: 16, Name: 'Advances'},
      {Id: 17, Name: 'Visa Management'},
      {Id: 18, Name: 'Customer Contract'},
      {Id: 19, Name: 'Customer Cashmanagement '},
      {Id: 20, Name: 'Direct Cash Collection'},
      {Id: 21, Name: 'Sim Expense'},
      {Id: 22, Name: 'Genral Expense'},
      {Id: 23, Name: 'Traffic Fine Expense'},
  ];

  ngOnInit() {
     this.initializeProps();
      this.getFormData();
      this.FormVm.EndDate = new Date();
      this.maxDate.setHours(this.maxDate.getHours() + 5);
      this.SelectedFormName ="";
  }
  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }

  public getFormData() {
    debugger
    try {
      this._clientService.getFormList().subscribe((res: any) => {
        console.log(res);
        this.FormList = res;
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }, (err) => {
        this._notificationService.notify('danger', 'Something went wrong');
      });
    } catch (error) {
      console.log(error)
    }

  }

  checkDate(id){
    debugger
    this._clientService.getFormRestrictionByFormId(id).subscribe((res: any)=>{
      this.FormVm = res;
      this.FormVm.EndDate = new Date(res.EndDate);
      this.FormVm.EndDate.setHours(this.FormVm.EndDate.getHours() + 5);
    }, (err)=>{

    });
  }

  onSubmit() {
    debugger
    this.FormVm.Id =0;
    this.FormVm.FormName ="";
    this.FormVm.Fk_ClientId = new ItemViewModel(0,'');
    this.FormVm.FK_FormId = this.SelectedFormName;
    this.FormVm.EndDate.setHours(this.FormVm.EndDate.getHours() + 5);
    this._clientService.saveFormData(this.FormVm).subscribe((res: any) => {
      this.openDilog = false;
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.getFormData();
      this.FormVm = new FormRestrictionDefiningViewModel();
      this.SelectedFormName ="";
    }, (err) => {
      this.openDilog = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }

  public OpenDialogForEdit(item) {
    console.log(item);
    // this.ve.Id = id;
    this.FormVm = item;
    this.openDilog = true;

  }

  public closeDelete() {
    this.openDilogConfirmation = false;
  }

  public OpenDileogForCategory() {
    this.openDilog = true;

  }

  public close(status) {
    this.openDilog = false;
  }


  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    // this.getCategory();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    // this.sortData();
  }
  // private sortData() {
  //   this.kGrid.gridView = {
  //     data: orderBy(this.kGrid.data, this.kGrid.sort),
  //     total: this.kGrid.totalRecords
  //   };
  // }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    // this.getCategory();
  }

}
