import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LaddaModule } from 'angular2-ladda';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HeadsDefinigComponent } from './heads-definig/heads-definig.component';

import { SettingServices } from './setting.services';
import { AccountService } from '../accounts/accounts-services';
import { CategoryDefiningComponent } from './category-defining/category-defining.component';
import { StoreService } from '../store/store.services';
import { AccNatuerDefiningComponent } from './acc-natuer-defining/acc-natuer-defining.component';

import { ExpHeadsComponent } from './exp-heads/exp-heads.component';
import { HeaderFooterComponent } from './header-footer/header-footer.component';
import { HeaderPrintComponent } from './header-footer/header-print/header-print.component';
import { FooterPrintComponent } from './header-footer/footer-print/footer-print.component';
import { VisaExpenseDefiningComponent } from './visa-expense-defining/visa-expense-defining.component';
import { InsuranceExpenseDefiningComponent } from './insurance-expense-defining/Insurance-expense-defining.component';
import { CanDeactivateGuard } from '../../_guards/can-deactivate.guard';
import { CountrySettingComponent } from './country-setting/country-setting.component';
import { FormRestrictionsComponent } from './form-restrictions/form-restrictions.component';
import { DepartmentsComponent } from './departments/departments.component';
import { CompanyComponent } from './company/company.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { AreaZoneComponent } from './area-zone/area-zone.component';
import { EmployeeStatusComponent } from './employee-status/employee-status.component';
import { InventoryStatusComponent } from './inventory-status/inventory-status.component';
import { CitySettingComponent } from './city-setting/city-setting.component';
import { TemplatesComponent } from './templates/templates.component';
import { QuotationNoteComponent } from './quotation-note/quotation-note.component';
import { SalesPersonComponent } from './sales-person/sales-person.component';

@NgModule({

  imports: [
    CommonModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    GridModule,
    DropDownsModule,
    DateInputsModule,
    TooltipModule,
    LaddaModule,
    ButtonsModule,
    DialogsModule,
    TabsModule
  ],
  declarations: [HeadsDefinigComponent, CategoryDefiningComponent, AccNatuerDefiningComponent, ExpHeadsComponent, HeaderFooterComponent, HeaderPrintComponent, FooterPrintComponent,InsuranceExpenseDefiningComponent, VisaExpenseDefiningComponent, CountrySettingComponent, FormRestrictionsComponent, DepartmentsComponent, CompanyComponent, WarehouseComponent, AreaZoneComponent, EmployeeStatusComponent, InventoryStatusComponent, CitySettingComponent, TemplatesComponent, QuotationNoteComponent, SalesPersonComponent],
  providers: [SettingServices, AccountService, StoreService, CanDeactivateGuard],
  exports: [HeaderPrintComponent, FooterPrintComponent]
})
export class SettingsModule { }
