import { Component, OnInit } from '@angular/core';
import { HeaderFooter } from '../../setting.class';
import { SettingServices } from '../../setting.services';
import { NotificationService } from '../../../../_services/notification.service';

@Component({
  selector: 'app-footer-print',
  templateUrl: './footer-print.component.html',

})
export class FooterPrintComponent implements OnInit {

  public obj: HeaderFooter;
  public saving = false;
  public nowDate = new Date();
  constructor(
    private settingServices: SettingServices,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.obj = new HeaderFooter();
    this.getHeaderFooter()
  }
  public onSubmit() {
    this.settingServices.saveHeaderFootrer(this.obj).subscribe((res: any) => {
      if (res) {
        this.obj = res;
      }
    }, err => {
      if(err.error.msg !==null &&  err.error.msg !==undefined){
        this._notificationService.notify("danger", err.error.msg);
      }

    });
  }
  public getHeaderFooter() {
    this.settingServices.getHeaderFooter().subscribe((res: any) => {
      if (res) {
        this.obj = res;
      }
    }, err => {
      if(err.error.msg !==null &&  err.error.msg !==undefined){
        this._notificationService.notify("danger", err.error.msg);
      }
    });
  }
}
