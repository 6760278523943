import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { AuthenticationService } from '../../../_services/authentication.service';
import { ExportExcelService } from '../../../_services/export-excel.service';
import { NotificationService } from '../../../_services/notification.service';
import { AccountService } from '../../accounts/accounts-services';
import { ReportingService } from '../../reporting/reporting.service';
import { EbService } from '../eb-service.service';

@Component({
  selector: 'app-eb-pos-balance-sheet',
  templateUrl: './eb-pos-balance-sheet.component.html',
  styleUrls: ['./eb-pos-balance-sheet.component.scss']
})
export class EbPosBalanceSheetComponent implements OnInit {
  public kGrid: KGridHelper = new KGridHelper();
  public ToDate: Date = null;
  maxDate= new Date();
  totalCodAmount:number=0;
  totalReaminingAmount:number=0;
  totalEbCharges:number=0;
  constructor(public _accountService: AccountService,
    public _notificationService: NotificationService,
    public _reportingServices: ReportingService,
    public _authenticationService: AuthenticationService,
    private _exportToExcelService: ExportExcelService,
    private ebService: EbService
    
  ) {
    this.ToDate = new Date();
   }

  ngOnInit() {
  }
  public GetUnPaidPOSGroupByEmployee() {
    this.totalCodAmount =0;
    this.kGrid.loading = true;
    this.ebService.GetUnPaidPOSGroupByEmployee(this.ToDate).subscribe(
      (response: any) => {
        console.log('report response',response);
        this.kGrid.data = [];
        this.kGrid.data = response;
        if(this.kGrid.data.length > 0){
          this.kGrid.data.forEach(x=>{
            this.totalCodAmount +=x.OrderAmount;
            this.totalEbCharges +=x.EbCharges;
          });
        }
        this.kGrid.totalRecords = response.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
        console.log(response);
      },eror =>{
        this.kGrid.loading = false;
      }
    );
  }
}
