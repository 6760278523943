import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { LivePreviewService } from '../../_services/live-preview.service';
import { ApplicationMaster } from '../../modules/application-fts/application-fts';

@Component({
  selector: 'app-application-fts-live-preview',
  templateUrl: './application-fts-live-preview.component.html'
})
export class ApplicationFtsLivePreviewComponent implements OnInit, OnDestroy {
  public htmlLive: any;
  private subscription: Subscription;
  constructor(private _livePreviewService: LivePreviewService) { }
  ngOnInit() {
      this._livePreviewService.update('');
      setInterval(() => {
      let html = this._livePreviewService.getLivePreview();
      if(html != this.htmlLive){
       
      }
      this.htmlLive = html;
      }, 1000);
    /*  this.subscription = this._livePreviewService.getLivePreview().subscribe(
       (x: any) => {
         console.log(x);
         this.htmlLive = x;
       }); */
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
