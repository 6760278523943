import { BulkEbPosListComponent } from './bulk-eb-pos-list/bulk-eb-pos-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EbOrdersComponent } from './eb-orders/eb-orders.component';
import { AddEbOrdersComponent } from './add-eb-orders/add-eb-orders.component';
import { EbBulkOrdersComponent } from './eb-bulk-orders/eb-bulk-orders.component';
import { BulkEbCashComponent } from './bulk-eb-cash/bulk-eb-cash.component';
import { BulkEbCashListComponent } from './bulk-eb-cash-list/bulk-eb-cash-list.component';
import { BulkEbPosComponent } from './bulk-eb-pos/bulk-eb-pos.component';
import { EbCashBalanceSheetComponent } from './eb-cash-balance-sheet/eb-cash-balance-sheet.component';
import { EbPosBalanceSheetComponent } from './eb-pos-balance-sheet/eb-pos-balance-sheet.component';
import { EbPosBalanceSheetDetailComponent } from './eb-pos-balance-sheet-detail/eb-pos-balance-sheet-detail.component';
import { EbCashBalanceSheetDetailComponent } from './eb-cash-balance-sheet-detail/eb-cash-balance-sheet-detail.component';
import { ViewEbOrderComponent } from './view-eb-order/view-eb-order.component';

const routes: Routes = [
  {path:'eb-orders', component: EbOrdersComponent, data:{title:'Errands Boys Orders'}},
  {path:'eb-bulk-detail',component:ViewEbOrderComponent, data:{title:'Eb Oder Detail'}},
  {path:'add-orders', component: AddEbOrdersComponent, data:{title:'Add Errands Boys Orders'}},
  {path:'bulk-eb-pos', component: BulkEbPosComponent, data:{title:'Bulk EB POS Return'}},
  {path:'bulk-eb-bank', component: EbBulkOrdersComponent, data:{title:'Bulk EB Bank Return'}},
  {path:'bulk-eb-cash', component: BulkEbCashComponent, data:{title:'Bulk EB CASH Return'}},
  {path:'bulk-eb-bank-list', component:BulkEbPosListComponent, data:{title:'Bulk EB POS List'}},
  {path:'bulk-eb-list', component:BulkEbCashListComponent, data:{title:'Bulk EB CASH List'}},
  {path:'eb-cash-balance-sheet',component: EbCashBalanceSheetComponent,data:{title:'EB Cash Balance Sheet'}},
  {path:'eb-pos-balance-sheet',component: EbPosBalanceSheetComponent,data:{title:'EB POS Balance Sheet'}},
  {path:'eb-cash-balance-sheet-detail',component: EbCashBalanceSheetDetailComponent,data:{title:'EB Cash Balance Sheet Detail'}},
  {path:'eb-pos-balance-sheet-detail',component: EbPosBalanceSheetDetailComponent,data:{title:'EB POS Balance Sheet Detail'}},

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrandsBoysRoutingModule { }
