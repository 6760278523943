import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrandsBoysRoutingModule } from './errands-boys-routing.module';
import { EbOrdersComponent } from './eb-orders/eb-orders.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { SlideToggleModule } from 'ngx-slide-toggle';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LaddaModule } from 'angular2-ladda';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AddEbOrdersComponent } from './add-eb-orders/add-eb-orders.component';
import { AccountService } from '../accounts/accounts-services';
import { StoreService } from '../store/store.services';
import { EmployeeServices } from '../employee/employee-services';
import { ReportingService } from '../reporting/reporting.service';
import { CanDeactivateGuard } from '../../_guards/can-deactivate.guard';
import { CoustomerServices } from '../coustomer/coustome-services';
import { ViewEbOrderComponent } from './view-eb-order/view-eb-order.component';
import { EbBulkOrdersComponent } from './eb-bulk-orders/eb-bulk-orders.component';
import { BulkEbCashComponent } from './bulk-eb-cash/bulk-eb-cash.component';
import { BulkEbCashListComponent } from './bulk-eb-cash-list/bulk-eb-cash-list.component';
import { BulkEbPosListComponent } from './bulk-eb-pos-list/bulk-eb-pos-list.component';
import { BulkEbPosComponent } from './bulk-eb-pos/bulk-eb-pos.component';
import { EbCashBalanceSheetComponent } from './eb-cash-balance-sheet/eb-cash-balance-sheet.component';
import { EbPosBalanceSheetComponent } from './eb-pos-balance-sheet/eb-pos-balance-sheet.component';
import { EbPosBalanceSheetDetailComponent } from './eb-pos-balance-sheet-detail/eb-pos-balance-sheet-detail.component';
import { EbCashBalanceSheetDetailComponent } from './eb-cash-balance-sheet-detail/eb-cash-balance-sheet-detail.component';
@NgModule({
  declarations: [EbOrdersComponent, AddEbOrdersComponent, ViewEbOrderComponent, EbBulkOrdersComponent, BulkEbCashComponent, BulkEbCashListComponent, BulkEbPosListComponent, BulkEbPosComponent, EbCashBalanceSheetComponent, EbPosBalanceSheetComponent, EbPosBalanceSheetDetailComponent, EbCashBalanceSheetDetailComponent],
  imports: [
  
    ErrandsBoysRoutingModule,
    // AutocompleteLibModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    GridModule,
    SlideToggleModule,
    DropDownsModule,
    DateInputsModule,
    TooltipModule,
    LaddaModule,
    ButtonsModule,
    DialogsModule,
    TabsModule
  ],
  providers: [AccountService, StoreService, EmployeeServices, ReportingService, CanDeactivateGuard, CoustomerServices]
})
export class ErrandsBoysModule { }
