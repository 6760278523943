import { Component, OnInit, ViewChild } from "@angular/core";
import { KGridHelper } from "../../../_helpers/k-grid.class";
import { Subject, Subscription } from "rxjs";
import { PageChangeEvent } from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { EBOrdersViewModel, EBOrderReturn, EBOrderInstallmentEditViewModel } from "../eb-orders-class";
import { StoreService } from "../../store/store.services";
import { EmployeeServices } from "../../employee/employee-services";
import { NotificationService } from "../../../_services/notification.service";
import { CoustomerServices } from "../../coustomer/coustome-services";
import { Router } from "@angular/router";
import { EbService } from "../eb-service.service";
import { ItemViewModel } from "../../store/store-model";
import { debounceTime } from "rxjs/operators";
import { ExportExcelService } from "../../../_services/export-excel.service";

@Component({
  selector: "app-eb-orders",
  templateUrl: "./eb-orders.component.html",
  styleUrls: ["./eb-orders.component.scss"],
})
export class EbOrdersComponent implements OnInit {
  @ViewChild("auto") auto;
  public _ebOrderViewModel = new EBOrdersViewModel();
  public _ebOrderReturnVm = new EBOrderReturn();
  public ebOrderInstallmentEditVm = new EBOrderInstallmentEditViewModel();
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  customerList: Array<any> = [];
  cashBankAccountList: Array<any> = [];
  ebOrderInstallments: Array<any> = [];
  keyword = "Number";
  data = [];
  paymentMethods: Array<any> = [
    {
      Id: 1,
      Name: "POS",
    },
    {
      Id: 2,
      Name: "CASH",
    },
  ];
  maxDate = new Date();
  employeeAccountList: Array<any> = [];
  public kGrid: KGridHelper = new KGridHelper();

  openDialog: boolean = false;
  openEditDialouge: boolean = false;
  public openEbReturn: boolean = false;
  public paidByTasker: any;
  public paidToTasker: any;
  public ebCharge: any;
  public ordrAmt: any;
  public amount: any;
  istallmentLoading: boolean = false;
  returnEdit: boolean = false;
  public primaryId: number;
  public isDuplicate: boolean = false;
  public duplicateFlag: boolean = false;
  public isInstallment:boolean= false;
  public orderId:any;
  statsObject:any={};
  public openDilougeView:boolean = false;
  viewModel=new EBOrdersViewModel();
  constructor(
    private _storeService: StoreService,
    private _employeeService: EmployeeServices,
    private _notificationService: NotificationService,
    private _customerService: CoustomerServices,
    private router: Router,
    private _ebService: EbService,
    private _exportToExcelService: ExportExcelService
  ) {
    this._ebOrderViewModel.CreditAccount = new ItemViewModel();
  }

  ngOnInit() {
    this.handleSearchEvents();
    this.GetEBOrderListtPaginate();
    this.maxDate.setHours(this.maxDate.getHours() + 5);
    this._ebOrderViewModel.OrderDate = new Date();
    this.getEmployeeAccountList("", "E");
    this.getCustomerList();
    this.getCashBankAccount("", "CaB");
  }
  public getEmployeeAccountList(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("emp list", res);
        if (res) {
          if (event === "E") {
            this.employeeAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  public handleSearchEvents() {
    console.log("cll");
    this.searchSubcription = this.searchEvent
      .pipe(debounceTime(400))
      .subscribe((x: any) => {
        console.log(x);
        if (x) {
          if (x.filter == "FK_Emp_Acc") {
            this.getEmployeeAccountList(x.event, "E");
          }
          if (x.filter == "FK_Dabit_Acc") {
            this.getCashBankAccount(x.event, "CaB");
          }
          // if (x.filter == 'FK_Customer_Acc') {
          //   this.getCustomerAccountList(x.event, 'C');
          // }
        }
      });
  }
  public getCashBankAccount(x: string, event: string) {
    this._storeService.getAccountBySearch(x, event).subscribe(
      (res: any) => {
        console.log("emp list", res);
        if (res) {
          if (event === "CaB") {
            this.cashBankAccountList = res;
          }
        }
      },
      (err) => {
        this._notificationService.notify("danger", err.msg);
      }
    );
  }
  isExcelExport:boolean = false;
  exportToExcel(){
    this.isExcelExport = true;
    let excelArr=[];
  
    for(let i =0; i < this.kGrid.data.length; i++) {
      let obj ={
        OrderNumber:this.kGrid.data[i].OrderNumber,
        Customer:this.kGrid.data[i].CustomerName +'-'+ this.kGrid.data[i].CustomerNumber,
        EbTasker:this.kGrid.data[i].EmployeAccount_Id.Name,
        PaymentMethod:this.kGrid.data[i].PaymentMethod.Name,
        OrderAmount:this.kGrid.data[i].OrderAmount,
        EBCharges:this.kGrid.data[i].EBCharges
      }
      excelArr.push(obj);
    }
    this.isExcelExport = false;
    this._exportToExcelService.exportAsExcelFile(excelArr,'EB-Orders');
    console.log('new array',excelArr);
}
  getCustomerList() {
    this._ebService.GetEbCustomersList().subscribe((res: any) => {
      console.log("res", res);
      this.customerList = res;
    });
  }
  selectEvent(item) {
    console.log(item);
    this._ebOrderViewModel.CustomerName = item.Name;
    this._ebOrderViewModel.CustomerNumber = item.Number;
    this._ebOrderViewModel.FK_Customer_Id = new ItemViewModel(
      item.Id,
      item.Name
    );
    // do something with selected item
    this.auto.close();
  }

  onChangeSearch(val: string) {
    console.log(val);
    this.data = this.customerList;
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }
  onEnter(event) {
    console.log(event);
    this._ebOrderViewModel.CustomerNumber = event;
  }
  randomNumberGenerator() {
    this._ebOrderViewModel.OrderNumber = "";
    // const randomString = Date.now() + Math.floor(Math.random() * 100 + 1);
    const length = 1000 + this.kGrid.totalRecords;
    // let randomString = Math.random().toString(36).substring(7);
    this._ebOrderViewModel.OrderNumber = "EB-" + length;
    // this.checkOrderIdDuplication();
    // console.log("random", randomString);
  }
  public saving: boolean = false;
  onSubmitOrder() {
    let ebOrder;
    if (this._ebOrderViewModel.IsReturn == undefined) {
      this._ebOrderViewModel.IsReturn = false;
    }
      ebOrder = Object.assign({}, this._ebOrderViewModel);
    this.saving = true;
    this._ebService
      .SaveUpdateEbOrders(ebOrder)
      .toPromise()
      .then(() => {
        this.saving = false;
        this.openDialog = false;
        this.openEditDialouge = false;
        // this.openEbReturn = false;
        this._notificationService.notify("success", "Save Successfully");
        this.GetEBOrderListtPaginate();
      })
      .catch((err) => {
        this.saving = false;
        this._notificationService.notify("danger", err.error);
      });
  }
  private initializeProps() {
    // this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }

  OpenDialog() {
    this._ebOrderViewModel = new EBOrdersViewModel();
    this.randomNumberGenerator();
    this._ebOrderViewModel.PaymentMethod = new ItemViewModel(
      this.paymentMethods[1].Id,
      this.paymentMethods[1].Name
    );
    this._ebOrderViewModel.CreditAccount = new ItemViewModel();
    this._ebOrderViewModel.CustomerAccount_Id = new ItemViewModel();
    this._ebOrderViewModel.FK_Customer_Id = new ItemViewModel();
    this._ebOrderViewModel.FK_EBTasker_Id = new ItemViewModel();
    this._ebOrderViewModel.DebitAccount = new ItemViewModel();
    this._ebOrderViewModel.POSAccount = new ItemViewModel();
    this._ebOrderViewModel.OrderDate = new Date();
    this.openDialog = true;
  }
  CloseDialog() {
    this.openDialog = false;
  }
  ///////////////////////////Grid Section////////////////////
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == "asd") {
      return;
    }
    this.kGrid.sort = sort;
    this.sortData();
  }

  private sortData() {
    this.kGrid.gridView = {
      data: orderBy(this.kGrid.data, this.kGrid.sort),
      total: this.kGrid.totalRecords,
    };
  }

  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
  }

  private handleError(err: any) {
    this.kGrid.loading = false;
    if (err.status == 403) {
    }
  }

  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    this.GetEBOrderListtPaginate();
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      this._ebService.GetEBOrderListtPaginate(this.kGrid.skip, this.kGrid.pageSize, this.kGrid.searchQuery,
        this.kGrid.hfId, this.kGrid.geoLvlCode, this.kGrid.hfType, this.kGrid.stockId, this.searchTerm).subscribe(
          (response: any) => {
            console.log(response);

            this.kGrid.data = [];
            this.kGrid.data = response.DtlList;
            this.kGrid.totalRecords = response.TotalCount;
            this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
            this.kGrid.loading = false;
        }, (error) => {
        console.log(error);
      });
    } else {
      this.GetEBOrderListtPaginate();
    }
  }
  GetEBOrderListtPaginate(){
    this.kGrid.loading = true;
    // tslint:disable-next-line: max-line-length
    this._ebService.GetEBOrderListtPaginate(this.kGrid.skip, this.kGrid.pageSize, this.kGrid.searchQuery, this.kGrid.hfId, this.kGrid.geoLvlCode, this.kGrid.hfType, this.kGrid.stockId,'').subscribe(
      (response: any) => {
        console.log(response);
        this.kGrid.data = [];
        this.kGrid.data = response.DtlList;
        this.kGrid.totalRecords = response.TotalCount;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
        console.log(this.kGrid.gridView);

      },
      err => this.handleError(err)
    );
  }
  GetEBOrderListt() {
    this.kGrid.loading = true;
    this._ebService.GetEBOrderListt().subscribe(
      (response: any) => {
        console.log("petty cash response", response);
        this.kGrid.data = [];
        this.kGrid.data = response;
        console.log("response data", this.kGrid.data);
        this.kGrid.totalRecords = 1;
        this.kGrid.gridView = {
          data: this.kGrid.data,
          total: this.kGrid.totalRecords,
        };
        this.kGrid.loading = false;
        console.log(this.kGrid.gridView);
      },error =>{
        this.kGrid.loading = false;
      });
  }
  
  checkOrderIdDuplication() {
    this.duplicateFlag = false;
    for (let i of this.kGrid.data) {
      if (i.OrderNumber == this._ebOrderViewModel.OrderNumber) {
        console.log("fid duplicate ");
        this.duplicateFlag = true;
      }
    }
    if (this.duplicateFlag) {
      this.isDuplicate = true;
    } else {
      this.isDuplicate = false;
    }
  }
  isDuplicateOrder(flag) {
    return flag;
  }
  getInstallments(id) {
    this.istallmentLoading = true;
    this.loading = true;
    this.ebOrderInstallments = [];
    this._ebService.GetInstalmentsByEBId(id).subscribe(
      (res: any) => {
        console.log("installment res", res);
        this.ebOrderInstallments = res;
        this.istallmentLoading = false;
        this.loading = false;
      },
      (error) => {
        this.istallmentLoading = false;
        this.loading = false;
      }
    );
  }
  
  openEbOrderReturn(model) {
    this._ebOrderReturnVm = new EBOrderReturn();
    this.amount = 0;
    this.istallmentLoading = true;
    this.primaryId = model.Id;
    this.getInstallments(this.primaryId);
    console.log(model);
    this._ebOrderReturnVm = model;
    this._ebOrderReturnVm.Remarks = undefined;
    this._ebOrderReturnVm.DebitAccount = new ItemViewModel();
    this._ebOrderReturnVm.CreditAccount = new ItemViewModel();
    this.returnEdit = true;
    this._ebOrderReturnVm.IsReturn = true;
    this._ebOrderReturnVm.FK_Customer_Id = new ItemViewModel(
      model.FK_Customer_Id.Id,
      model.FK_Customer_Id.Name
    );
    this._ebOrderReturnVm.FK_EBOredr_Id = model.Id;
    
    this._ebOrderReturnVm.OrderDate = new Date(model.OrderDate);
    if (model.PaymentMethod.Id === 1) {
      this.statsObject.paid = model.PaidAmountToTasker;
      this.statsObject.remaining = model.OrderAmount - model.PaidAmountToTasker; 
      this.ordrAmt = model.OrderAmount;
      this.paidToTasker = model.OrderAmount - model.EBCharges;
      this.amount = this.paidToTasker;
    } else {
      this.statsObject.paid = model.PaidAmountByTasker;
      this.statsObject.remaining = model.EBCharges - model.PaidAmountByTasker;
      this.ebCharge = model.EBCharges;
      this.paidByTasker = model.EBCharges - model.PaidAmountByTasker;
      this.amount = this.paidByTasker;
    }
    this.isInstallment = false;
    this.openEbReturn = true;
  }
  openEdit(model) {
    console.log(model);
    this._ebOrderViewModel = model;
    this._ebOrderViewModel.OrderDate = new Date(model.OrderDate);
    this._ebOrderViewModel.IsReturn = undefined;
    this._ebOrderViewModel.POSAccount = new ItemViewModel();
    this.openEditDialouge = true;
  }
  
  
  onInstallemntEditClick(model) {
    console.log("edit click", model);
    this._ebOrderReturnVm = new EBOrderReturn();
    if (model !== undefined) {
      this._ebOrderReturnVm = model;
      this._ebOrderReturnVm.Remarks = undefined;
      this._ebOrderReturnVm.POSAccount = new ItemViewModel();
      if (model.PaymentMethod.Id === 1) {
        this.ordrAmt = model.OrderAmount;
        this.paidToTasker = model.OrderAmount - model.EBCharges;
        this.amount = this.paidToTasker;
      } else {
        this.ebCharge = model.EBCharges;
        this.paidByTasker = model.EBCharges - model.PaidAmountByTasker;
        this.amount = this.paidByTasker;
      }
      this._ebOrderReturnVm.OrderDate = new Date(model.OrderDate);
      this.isInstallment = true;
      this.returnEdit = false;
    }
  }
  closeCashReturnDilouge() {
    this._ebOrderReturnVm = new EBOrderReturn();
    this.ordrAmt = undefined;
    this.ebCharge = undefined;
    this.paidByTasker = undefined;
    this.paidToTasker = undefined;
    this.isInstallment = false;
    this.openEbReturn = false;
  }
  submitEbOrderReturn() {
    let ebOrder;
    if (this.paidToTasker !== undefined && this._ebOrderReturnVm.PaymentMethod.Id === 1) {
      this._ebOrderReturnVm.OrderAmount = this.paidToTasker;
    }
    if (this.paidByTasker !== undefined && this._ebOrderReturnVm.PaymentMethod.Id === 2) {
      this._ebOrderReturnVm.EBCharges = this.paidByTasker;
    }
      this._ebOrderReturnVm.Id = 0;
      ebOrder = Object.assign({}, this._ebOrderReturnVm);
    this.saving = true;
    this._ebService
      .SaveUpdateEbOrders(ebOrder)
      .toPromise()
      .then(() => {
        this.saving = false;
        this.openDialog = false;
        this.openEditDialouge = false;
        this._notificationService.notify("success", "Save Successfully");
        this.closeCashReturnDilouge();
        this.GetEBOrderListt();
      })
      .catch((err) => {
        this.saving = false;
        this._notificationService.notify("danger", err.error);
      });
  }
  submitEbOrderEditReturn() {
    let ebOrder;
    if (this.paidToTasker !== undefined) {
      this._ebOrderReturnVm.OrderAmount = this.paidToTasker;
    }
    if (this.paidByTasker !== undefined) {
      this._ebOrderReturnVm.EBCharges = this.paidByTasker;
    }
      ebOrder = Object.assign({}, this._ebOrderReturnVm);
    this.saving = true;
    this._ebService
      .SaveUpdateEbOrders(ebOrder)
      .toPromise()
      .then(() => {
        this.saving = false;
        this.openDialog = false;
        this.openEditDialouge = false;
        this._notificationService.notify("success", "Save Successfully");
        this.closeCashReturnDilouge();
        this.GetEBOrderListt();
      })
      .catch((err) => {
        this.saving = false;
        this._notificationService.notify("danger", err.error);
      });
  }
  public loading:boolean= false;
  openViewPage(model){
    console.log(model);
    this.primaryId = model.Id;
    this.getInstallments(this.primaryId);
    this.viewModel = model;
    this.openDilougeView = true;
  }
  closeViewPage(){
    this.openDilougeView = false;;
  }
}
