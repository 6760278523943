import { NotificationService } from './../../../_services/notification.service';
import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { InventoryStatusViewModel } from '../setting.class';
import { SettingServices } from '../setting.services';

@Component({
  selector: 'app-inventory-status',
  templateUrl: './inventory-status.component.html',
  styleUrls: ['./inventory-status.component.scss']
})
export class InventoryStatusComponent implements OnInit {
  InventoryStatusViewModel = new InventoryStatusViewModel();
  public openDilog = false;
public openDilogConfirmation = false;
loading:boolean = false;
public kGrid: KGridHelper = new KGridHelper();
  constructor(private _settingsService : SettingServices,
    private _notificationService: NotificationService) { }

  ngOnInit() {
    this.initializeProps();
    this.GetInventoryStatusList();
  }
  private initializeProps() {
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  
  }
  public GetInventoryStatusList() {

      this.kGrid.loading = true;
      this._settingsService.GetInventoryStatusList().subscribe((res: any) => {
        console.log('list',res)
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      },error =>{
        this.kGrid.loading = false;
        // this._notificationService.notify('danger', 'Something went wrong');
        console.log(error)
      });

      
  }
  onSubmit() {
    debugger;
    this.loading = true;
    let InventoryStatus: any = Object.assign({}, this.InventoryStatusViewModel);
    this._settingsService.SaveInventoryStatus(InventoryStatus).subscribe((res: any) => {
      this.loading = false;
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.GetInventoryStatusList();
      this.closeStatusModal();
    }, (err) => {
      console.log('error',err);
      this.loading = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }
  public OpenDialogForEdit(item) {
    debugger;
        // this._settingsService.GetInventoryStatusById(item).toPromise().then((res:any) =>{
        //   console.log('edit res',res);
        //   //  this.InventoryStatusViewModel = res;
        // });
        this.InventoryStatusViewModel = item;
    this.openDilog = true;
  }
  public RemoveEmployeeStatus(e) {
    this.InventoryStatusViewModel = e;
    this.openDilogConfirmation = true;
  }
  public ConfirmDelete() {
    this.loading = true;
    this._settingsService.DeleteInventoryStatus(this.InventoryStatusViewModel).subscribe((res: any) => {
      this.loading = false;
      this._notificationService.notify('success', 'Delete Successful');
      this.GetInventoryStatusList();
      this.openDilogConfirmation = false;
    }, err => {
      this.loading = false;
      this._notificationService.notify('danger', err.error);

    });
  }
  public closeDelete() {
    this.openDilogConfirmation = false;
  }
  public OpenEmployeeStatusModal() {
    this.openDilog = true;
  }
  public closeStatusModal() {
    this.InventoryStatusViewModel = new InventoryStatusViewModel();
    this.openDilog = false;
  }
  public duplicateFlag:boolean =false;
  isDuplicate:boolean = false;
  checkEmployeeStausDuplication(){
    debugger;
    this.duplicateFlag = false;
  for(let i of this.kGrid.data){
      if(i.Name.toUpperCase() == this.InventoryStatusViewModel.Name.toUpperCase()){
      console.log('fid duplicate ');
      this.duplicateFlag =true;
    }
    if(i.Name.toLowerCase() == this.InventoryStatusViewModel.Name.toLowerCase()){
      console.log('fid duplicate ');
      this.duplicateFlag =true;
    }
  }
  if(this.duplicateFlag){
    this.isDuplicate =true;
  }
  else{
    this.isDuplicate = false;
  }
  }
  isDuplicateOrder(flag){
    return flag;
  }
}
