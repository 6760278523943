import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EbService } from '../eb-service.service';

@Component({
  selector: 'app-view-eb-order',
  templateUrl: './view-eb-order.component.html',
  styleUrls: ['./view-eb-order.component.scss']
})
export class ViewEbOrderComponent implements OnInit {
ebId:any;
Detailsloading:boolean = false;
ebList:Array<any>=[];
totalOrderAmount:number=0;
totalEbCharges:number=0;
  constructor(private activatedRoute: ActivatedRoute,private ebOrderService: EbService) {
    this.activatedRoute.queryParams.subscribe(params =>{
      const id =params['id'];
      if(id){
         this.ebId = id;
      }
    })
   }

  ngOnInit() {
    debugger;
    if(this.ebId !== undefined){
      this.GetInstalmentListBySettlement(this.ebId);
    }
  }
  GetInstalmentListBySettlement(id){

    this.Detailsloading = true;
    this.ebOrderService.GetInstalmentListBySettlement(id).toPromise().then((res:any) =>{
      console.log('get res',res);
        this.ebList = res;
        this.ebList.forEach(x =>{
          this.totalOrderAmount +=x.OrderAmount;
          this.totalEbCharges +=x.EBCharges;
        });
        this.Detailsloading = false;
    }).catch(error =>{
      this.Detailsloading = false;
    });
  }
}
