import { Component, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { NotificationService } from '../../../_services/notification.service';
import { CitiesViewModel } from '../setting.class';
import { SettingServices } from '../setting.services';

@Component({
  selector: 'app-city-setting',
  templateUrl: './city-setting.component.html',
  styleUrls: ['./city-setting.component.scss']
})
export class CitySettingComponent implements OnInit {
  CitiesViewModel = new CitiesViewModel();
  public kGrid: KGridHelper = new KGridHelper();
  public CityList: Array<any> = [];
  public openDilog = false;
  public openDilogConfirmation = false;
  saving:boolean = false;
  constructor( public _settingService: SettingServices,
    private _notificationService: NotificationService) { }

  ngOnInit() {
    this.initializeProps();
    this.GetCitiesList();
  }
  private initializeProps() {
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }
  public GetCitiesList() {
    try {
      this.kGrid.loading = true;
      this._settingService.GetCitiesList().subscribe((res: any) => {
        this.CityList = res;
        console.log(res);
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }, (err) => {
        this._notificationService.notify('danger', 'Something went wrong');
      });
    } catch (error) {
      console.log(error)
    }
  }
  onSubmit() {
    this.saving = true;
    let city: any = Object.assign({}, this.CitiesViewModel);
    this._settingService.SaveCities(city).subscribe((res: any) => {
      this.saving = false;
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.close();
     this.GetCitiesList();
    }, (err) => {
      this.saving = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }
  localObject:any ={};
  public OpenDialogForEdit(item) {
    console.log(item);
    this.localObject = item;
    this.CitiesViewModel = item;
    this.openDilog = true;
  }
  deleting:boolean = false;
  public ConfirmDelete() {
    this.deleting = true;
    this._settingService.DeleteCities(this.CitiesViewModel).subscribe((res: any) => {
      this.deleting = false;
      this._notificationService.notify('success', 'Delete Successful');
      this.GetCitiesList();
      this.closeDelete();
    }, err => {
      this.deleting = false;
      this._notificationService.notify('danger', err.error);

    });
  }
  openDilougeForAddCities(){
    this.openDilog = true;
  }
  public DeleteCities(e) {
     this.CitiesViewModel = e;
    this.openDilogConfirmation = true;
  }
  public closeDelete() {
    this.CitiesViewModel = new CitiesViewModel();
    this.openDilogConfirmation = false;
  }
  public close() {
    this.CitiesViewModel = new CitiesViewModel();
    this.openDilog = false;
  }
  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
   
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;

  }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
  
  }
}
