import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { StoreService } from '../../store/store.services';
import { EmployeeServices } from '../../employee/employee-services';
import { NotificationService } from '../../../_services/notification.service';
import { Router } from '@angular/router';
import { EbService } from '../eb-service.service';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { ExportExcelService } from '../../../_services/export-excel.service';

@Component({
  selector: 'app-bulk-eb-cash-list',
  templateUrl: './bulk-eb-cash-list.component.html',
  styleUrls: ['./bulk-eb-cash-list.component.scss']
})
export class BulkEbCashListComponent implements OnInit {

  public kGrid: KGridHelper = new KGridHelper();
  public openDilouge:boolean = false;
  viewObj:any={};
  EBTransactionDetails:Array<any> =[];
  constructor(
    private _storeService: StoreService,
    private _employeeService: EmployeeServices,
    private _notificationService: NotificationService,
    private router: Router,
    private _ebService: EbService,
    private _exportToExcelService: ExportExcelService
  ) {
    
  }

  ngOnInit() {
    this.initializeProps();
    this.GetEBSettlement();
  }
  isExcelExport:boolean = false;
  exportToExcel(){
    this.isExcelExport = true;
    let excelArr=[];
  
    for(let i =0; i < this.kGrid.data.length; i++) {
      const SettlementType =this.kGrid.data[i].Type === 1 ? 'POS' :this.kGrid.data[i].Type === 2 ? 'CASH' : 'BANK';
      // const PaymentMethod  =this.kGrid.data[i].PaymentMethod === null ? 'none': this.kGrid.data[i].PaymentMethod.Name;
      let obj ={
        TransationDate:new Date(this.kGrid.data[i].OrderDate).toDateString() ,
        RefId:'EBRS'+'-'+ this.kGrid.data[i].Id,
        SettlementType:SettlementType,
        OrderAmount:this.kGrid.data[i].OrderAmount,
        EBCharges:this.kGrid.data[i].EBCharges
      }
      excelArr.push(obj);
    }
    this.isExcelExport = false;
    this._exportToExcelService.exportAsExcelFile(excelArr,'Bulk EB List');
    console.log('new array',excelArr);
}
  GetEBSettlement(){
    this.kGrid.loading = true;
    this._ebService.GetEBSettlement().subscribe(
      (response: any) => {
        console.log("bulk pos list", response);
        this.kGrid.data = [];
        this.kGrid.data = response;
        console.log("response data", this.kGrid.data);
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = {
          data: this.kGrid.data,
          total: this.kGrid.totalRecords,
        };
        this.kGrid.loading = false;
        console.log(this.kGrid.gridView);
      },error =>{
        this.kGrid.loading = false;
      });
  }
  Detailsloading:boolean = false;
  openViewPage(object){
    debugger;
    this.viewObj = object;
    this.Detailsloading = true;
    this._ebService.GetEBTransactionDetail(object.Id).subscribe(
      (response: any) => {
        console.log("bulk details list", response);
        this.EBTransactionDetails = response;
        this.Detailsloading = false;
      },error =>{
        this.Detailsloading = false;
      });
this.openDilouge = true;
  }
  closeViewPage(){
    this.openDilouge = false;;
  }
///////////////////////////Grid Section////////////////////
public sortChange(sort: SortDescriptor[]): void {
  if (sort[0].field == "asd") {
    return;
  }
  this.kGrid.sort = sort;
  this.sortData();
}

private sortData() {
  this.kGrid.gridView = {
    data: orderBy(this.kGrid.data, this.kGrid.sort),
    total: this.kGrid.totalRecords,
  };
}

public pageChange(event: PageChangeEvent): void {
  this.kGrid.skip = event.skip;
}

private handleError(err: any) {
  this.kGrid.loading = false;
  if (err.status == 403) {
  }
}

public changePagesize(value: any) {
  this.kGrid.pageSize = +value;
  this.kGrid.skip = 0;
  // this.GetEBOrderListt();
}
private initializeProps() {
  // this.kGrid.loading = false;
  this.kGrid.pageSize = 20;
  this.kGrid.pageSizes = [20, 50, 100];
}
}
