import { GridDataResult } from "@progress/kendo-angular-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";

export class KGridHelper {
  //loading flag
  public firstLoad = false;
  public loading = false;
  //grid data
  public dataOrigional: any[] = [];
  public data: any[] = [];

  public gridView: GridDataResult;
  //sorting variable
  public multiple = false;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [];
  //Pagination variables
  public pageSizes = [5, 10, 25, 50];
  public totalRecords = 0;
  public pageSize = 10;
  public skip = 0;
  public get showInitialPageSize() {
    return this.pageSizes
      .filter(num => num === Number(this.pageSize))
      .length === 0;
  }

  public searchQuery = "";


  //CoustomFilter
  public hfId = "0";
  public geoLvlCode = '';
  public hfType = '';
  public stockId: number;

  //To From
  public dateLimit = new Date();
  public fromDate: Date;
  public toDate: Date;

  //Sale-Purchase OderFilter;
  public spType: number;
}
