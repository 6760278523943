import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { NotificationService } from '../../../_services/notification.service';
import { DocumentsFormateViewModel, InventoryStatusViewModel } from '../setting.class';
import { SettingServices } from '../setting.services';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  DocumentsFormateViewModel = new DocumentsFormateViewModel();
  public openDilog = false;
public openDilogConfirmation = false;
loading:boolean = false;
public kGrid: KGridHelper = new KGridHelper();
  constructor(private _settingsService : SettingServices,
    private _notificationService: NotificationService) { }


  ngOnInit() {
    this.GetDocumentTemplatesList();
  }
  private initializeProps() {
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  
  }
  public GetDocumentTemplatesList() {

    this.kGrid.loading = true;
    this._settingsService.GetDocumentTemplatesList().subscribe((res: any) => {
      console.log('list',res)
      this.kGrid.data = [];
      this.kGrid.data = res;
      this.kGrid.totalRecords = this.kGrid.data.length;
      this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
      this.kGrid.loading = false;
    },error =>{
      this.kGrid.loading = false;
      // this._notificationService.notify('danger', 'Something went wrong');
      console.log(error)
    }); 
}
onSubmit() {
  debugger;
  this.loading = true;
  this.DocumentsFormateViewModel.Html = '';
  let template: any = Object.assign({}, this.DocumentsFormateViewModel);
  this._settingsService.SaveDocumentTemplatesList(template).subscribe((res: any) => {
    this.loading = false;
    this._notificationService.notify('success', 'Saved Successfully!!');
    this.GetDocumentTemplatesList();
    this.closeStatusModal();
  }, (err) => {
    console.log('error',err);
    this.loading = false;
    this._notificationService.notify('danger', 'Something went wrong');
  });
}
public OpenDialogForEdit(item) {
  debugger;
      // this._settingsService.GetInventoryStatusById(item).toPromise().then((res:any) =>{
      //   console.log('edit res',res);
      //   //  this.InventoryStatusViewModel = res;
      // });
      this.DocumentsFormateViewModel = item;
  this.openDilog = true;
}
public DeleteGetDocumentTemplatesList(e) {
  this.DocumentsFormateViewModel = e;
  this.openDilogConfirmation = true;
}
public ConfirmDelete() {
  this.loading = true;
  this._settingsService.DeleteGetDocumentTemplatesList(this.DocumentsFormateViewModel).subscribe((res: any) => {
    this.loading = false;
    this._notificationService.notify('success', 'Delete Successful');
    this.GetDocumentTemplatesList();
    this.openDilogConfirmation = false;
  }, err => {
    console.log('delete error',err);
    this.loading = false;
    this._notificationService.notify('danger', err.error);

  });
}
public closeDelete() {
  this.DocumentsFormateViewModel = new DocumentsFormateViewModel();
  this.openDilogConfirmation = false;
}
public OpenEmployeeStatusModal() {
  this.openDilog = true;
}
public closeStatusModal() {
  this.DocumentsFormateViewModel = new DocumentsFormateViewModel();
  this.openDilog = false;
}
public duplicateFlag:boolean =false;
  isDuplicate:boolean = false;
  checkCodeDuplication(){
    debugger;
    this.duplicateFlag = false;
  for(let i of this.kGrid.data){
      if(i.Name.toUpperCase() == this.DocumentsFormateViewModel.Code.toUpperCase()){
      console.log('fid duplicate ');
      this.duplicateFlag =true;
    }
    if(i.Name.toLowerCase() == this.DocumentsFormateViewModel.Code.toLowerCase()){
      console.log('fid duplicate ');
      this.duplicateFlag =true;
    }
  }
  if(this.duplicateFlag){
    this.isDuplicate =true;
  }
  else{
    this.isDuplicate = false;
  }
  }
  isDuplicateOrder(flag){
    return flag;
  }
}
