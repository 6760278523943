import { Component, OnInit } from '@angular/core';
import { Category } from '../setting.class';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { NotificationService } from '../../../_services/notification.service';
import { StoreService } from '../../store/store.services';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { LocalService } from '../../../_services/local.service';

@Component({
  selector: 'app-acc-natuer-defining',
  templateUrl: './acc-natuer-defining.component.html',

})
export class AccNatuerDefiningComponent implements OnInit {

  public openDilog = false;
  public openDilogCat = false;
  public category = new Category();


  public categoriesList: any[] = [];
  public catlogList: any[] = [];
  public kGrid: KGridHelper = new KGridHelper();
  constructor(public _notificationService: NotificationService,
    public _storeService: StoreService,
    private _localService: LocalService) {

  }

  ngOnInit() {
    this.initializeProps();
    this.getCategory();


  }
  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }
  public OpenDileogForCategory() {
    this.openDilog = true;
  }
  public close(status) {

    this.openDilog = false;
  }

  public onSubmit() {
    this._storeService.saveAccountNatuer(this.category).subscribe((res: any) => {
      this._notificationService.notify("success", "Save Successfully!");
      this.openDilog = false;
      this.category = new Category();
      this.getCategory();
    }, err => {
      this._notificationService.notify("danger", err.msg);

    });
  }
  public getCategory() {
    this._storeService.getAccountNatuers().subscribe((res: any) => {
      if (res) {
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }
    }, err => {
      this._notificationService.notify("danger", err.msg);

    });
  }
  public EditCategory(d) {
    this.category.Name = d.Name;
    this.category.Id = d.Id;
    this.openDilog = true;

  }
  public OpenDileogForCatlog() {
    this.openDilogCat = true;
  }
  public closeCat(status) {
    this.category = new Category();
    this.openDilogCat = false;
  }



  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    this.getCategory();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    this.sortData();
  }
  private sortData() {
    this.kGrid.gridView = {
      data: orderBy(this.kGrid.data, this.kGrid.sort),
      total: this.kGrid.totalRecords
    };
  }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    this.getCategory();
  }
}
