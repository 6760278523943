import { Component, OnInit } from '@angular/core';
import { Department } from '../setting.class';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NotificationService } from '../../../_services/notification.service';
import { CoustomerServices } from '../../coustomer/coustome-services';
import { EmployeeSalaryServices } from '../../emp-salary/emp-salary-services';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../store/store.services';
import { SettingServices } from '../setting.services';
import { VisaexpenseVieModel } from '../../employee/employee-class';
import { LocalService } from '../../../_services/local.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
  public openDilog = false;
  public openDilogCat = false;
  public DeptList: any = [];

  public kGrid: KGridHelper = new KGridHelper();
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  public expAccountList: any[] = [];
  public openDilogConfirmation = false;
  public dept: Department = new Department();
  constructor(public _notificationService: NotificationService,
    public _storeService: StoreService,
    public _settingService: SettingServices,
    private activatedRoute: ActivatedRoute,
    private _localService: LocalService) { }

  ngOnInit() {
     this.initializeProps();
    this.getDeptList();
  }

  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }

  public getDeptList() {
    debugger
    try {
      this.kGrid.loading = true;
      this._settingService.getDeptList().subscribe((res: any) => {
        this.DeptList = res;
        console.log(res);
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }, (err) => {
        this._notificationService.notify('danger', 'Something went wrong');
      });
    } catch (error) {
      console.log(error)
    }

  }

  onSubmit() {
    debugger
    let ct: any = Object.assign({}, this.dept);
    this._settingService.saveDept(ct).subscribe((res: any) => {
      this.openDilog = false;
      debugger
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.getDeptList();
      this.dept = new Department()
    }, (err) => {
      this.openDilog = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }

  public OpenDialogForEdit(item) {
    console.log(item);
    // this.ve.Id = id;
    this.dept = item;
    this.openDilog = true;

  }


  public ConfirmDelete() {
    debugger
    this._settingService.deleteDept(this.dept).subscribe((res: any) => {
      this.dept = null;
      this.openDilogConfirmation = false;
      this._notificationService.notify('success', 'Delete Successful');
      this.getDeptList();
    }, err => {
      this._notificationService.notify('danger', err.error);

    });
  }

  public RemoveDept(e) {
    this.dept = e;
    this.openDilogConfirmation = true;
  }



  public closeDelete() {
    this.openDilogConfirmation = false;
  }

  public OpenDileogForCategory() {
    this.openDilog = true;

  }

  public close(status) {
    this.openDilog = false;
  }


  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    // this.getCategory();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    // this.sortData();
  }
  // private sortData() {
  //   this.kGrid.gridView = {
  //     data: orderBy(this.kGrid.data, this.kGrid.sort),
  //     total: this.kGrid.totalRecords
  //   };
  // }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    // this.getCategory();
  }

}
