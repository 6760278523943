import { Nav } from "./_models/nav.class";

export const navItems: Nav[] = [
  // {
  //   name: 'DASHBAORD',
  //   url: '/dashboard',
  //   icon: 'fa fa-dashboard'
  // },
  // {
  //   name: 'DATABASE',
  //   url: '/database',
  //   icon: 'fa fa-database'
  // },
  // {
  //   name: 'HEALTH FACILITY',
  //   url: '/health-facility',
  //   icon: 'fa fa-hospital-o'
  // },
  // {
  //   name: 'EMPLOYEE PROFILE',
  //   url: '/profile',
  //   icon: 'fa fa-user-circle'
  // },
  // {
  //   name: 'VACANCY POSITION',
  //   url: '/vacancy-position',
  //   icon: 'fa fa-street-view'
  // },
  // {
  //   name: 'APPLICATION',
  //   url: '/application',
  //   icon: 'fa fa-file-text'
  // },
  // {
  //   name: 'ORDER / NOTIFICATION',
  //   url: '/order/0/1',
  //   icon: 'fa fa-file-text-o'
  // },
  // {
  //   name: 'REPORTING',
  //   url: '/reporting',
  //   icon: 'fa fa-table'
  // }/* ,
  // {
  //   name: 'RECORD ROOM',
  //   url: '/record-room',
  //   icon: 'fa fa-file'
  // } */,
  // {
  //   name: 'USER',
  //   url: '/user',
  //   icon: 'fa fa-user-circle-o'
  // }
];
