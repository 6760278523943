import { Component, OnInit } from '@angular/core';
import { LocalService } from '../../../_services/local.service';
import { Router } from '@angular/router';
import { ClientService } from '../../clients/clients.service';
import { AuthenticationService } from '../../../_services/authentication.service';

@Component({
  selector: 'app-locked-out',
  templateUrl: './locked-out.component.html',
})
export class LockedOutComponent implements OnInit {

  constructor(private router: Router,
    private clientsercice: ClientService,
    private _authenticationServie: AuthenticationService) { }

  ngOnInit() {

    let interval = setInterval(() => {
      // console.log(`workin everywhere ${new Date()}`)
      this.clientsercice.CheckClientBlock().subscribe((res: any) => {
        console.log('scheduler',res);
        if (res.IsBlock == false) {
          this.router.navigate(['/dashboard']);
          clearInterval(interval);
        }
      }, (err) => {

      });
    }, 500);
  }

  public logout() {
    this._authenticationServie.logout();
  }
  public subscription(){
    this.router.navigate(['account/subscription-plan']);
  }


}
