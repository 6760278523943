import { Component, OnInit } from '@angular/core';
import { Heads } from '../setting.class';
import { SettingServices } from '../setting.services';
import { NotificationService } from '../../../_services/notification.service';
import { AccountService } from '../../accounts/accounts-services';
import { ItemViewModel } from '../../store/store-model';
import { LocalService } from '../../../_services/local.service';

@Component({
  selector: 'app-heads-definig',
  templateUrl: './heads-definig.component.html',

})
export class HeadsDefinigComponent implements OnInit {
  public preHaed = new Heads();
  public head1 = new Heads();
  public head2 = new Heads();
  public savingph = false;
  public savingph1 = false;
  public savingph2 = false;

  public preHeadsList: any[];
  public heads1List: any[];
  public heads2List: any[];
  constructor(private settingServices: SettingServices,
    private _notificationService: NotificationService,
    public _accountService: AccountService,
    private _localService: LocalService) { }

  ngOnInit() {
    this.preHaed.Lvl = '1';
    this.preHaed.FK_ID = new ItemViewModel();

    this.head1.Lvl = '2';
    this.head2.Lvl = '3';
    this.getPreHeads();

  }
  public dropDownChange(e) {
    console.log(e);
    if (e.filter == "ph") {
      this.getHead1(e.event.Id);
      this.head2.FK_ID = null;
    }
    if (e.filter == "h1") {
      this.getHead2(e.event.Id);
    }

  }
  public getPreHeads() {
    this._accountService.getPreHeads().subscribe((res: any) => {
      if (res) {
        this.preHeadsList = res;
      }
    },
      err => {

      });

  }
  public getHead1(e: number) {
    this._accountService.getHead1(e).subscribe((res: any) => {
      if (res) {
        this.heads1List = res;
      }
    },
      err => {

      });
  }
  public getHead2(e: number) {
    this._accountService.getHead2(e).subscribe((res: any) => {
      if (res) {
        this.heads2List = res;
      }
    },
      err => {

      });
  }
  public savePH() {
    debugger;
    this.savingph = true;
    this.settingServices.savePrehead(this.preHaed).subscribe((res: any) => {
      debugger;
      this._notificationService.notify("success", "PreHead Save Successfully!");
      this.savingph = false;
      this.preHaed.Name = null;
      this.getPreHeads();
    }, err => {
      debugger;
      this._notificationService.notify("danger", err.error.msg);
      this.savingph = false;
    });
  }
  public clearPH() {
    this.preHaed = new Heads();
    this.preHaed.Lvl = '1';
    this.preHaed.FK_ID = new ItemViewModel();
  }
  public clearPH1() {
    this.head1 = new Heads();
    this.head1.Lvl = '2';

  }
  public clearPH2() {
    this.head2 = new Heads();
    this.head2.Lvl = '3';
  }
  public saveH1() {
    debugger;
    this.savingph1 = true;
    this.settingServices.savePrehead(this.head1).subscribe((res: any) => {
      debugger;
      this._notificationService.notify("success", "Head 1 Save Successfully!");
      this.savingph1 = false;
      this.head1.Name = null;
      this.head1.FK_ID = null
      this.getHead1(0);
    }, err => {
      debugger;
      this._notificationService.notify("danger", err.error.msg);
      this.savingph1 = false;

    });


  }
  public saveH2() {
    debugger;
    this.savingph2 = true;
    this.settingServices.savePrehead(this.head2).subscribe((res: any) => {
      debugger;
      this._notificationService.notify("success", "Head 1 Save Successfully!");
      this.savingph2 = false;
      this.head2.Name = null;
      this.head2.FK_ID = null;
      this.getHead2(0);

    }, err => {
      debugger;
      this._notificationService.notify("danger", err.error.msg);
      this.savingph2 = false;

    });


  }

  public EditPreHead(obj: any) {
    this.preHaed.Name = obj.Name;
    this.preHaed.Id = obj.Id;
  }
  public EditHead1(obj: any) {
    debugger;
    this.head1.Name = obj.Name;
    this.head1.Id = obj.Id;
    this.head1.FK_ID = new ItemViewModel();
    this.head1.FK_ID.Id = obj.FK_ID;
    this.head1.FK_ID.Name = obj.PH;
  }
  public EditHead2(obj: any) {
    debugger;
    this.head2.Name = obj.Name;
    this.head2.Id = obj.Id;
    this.head2.FK_ID = new ItemViewModel();
    this.head2.FK_ID.Id = obj.FK_ID;
    this.head2.FK_ID.Name = obj.PH1;
    this.head2.FK_ID_PH = new ItemViewModel();
    this.head2.FK_ID_PH.Id = obj.PHID;
    this.head2.FK_ID_PH.Name = obj.PH;
  }

  public RemoveHead(e) {
    this.settingServices.RemoveHead(e).subscribe((res: any) => {
      this._notificationService.notify("success", "Removed Successfully!");
      this.getPreHeads();
      this.getHead1(0);
      this.getHead2(0);


    }, err => {

      this._notificationService.notify("danger", err.error.msg);


    });
  }
}
