import { Component, OnInit } from '@angular/core';
import { KGridHelper } from '../../../_helpers/k-grid.class';
import { Subject, Subscription } from 'rxjs';
import { AreaZoneViewModel } from '../setting.class';
import { NotificationService } from '../../../_services/notification.service';
import { StoreService } from '../../store/store.services';
import { SettingServices } from '../setting.services';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from '../../../_services/local.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-area-zone',
  templateUrl: './area-zone.component.html',
  styleUrls: ['./area-zone.component.scss']
})
export class AreaZoneComponent implements OnInit {
  public openDilog = false;
  public openDilogCat = false;
  public areaZoneList: any = [];

  public kGrid: KGridHelper = new KGridHelper();
  public searchEvent = new Subject<any>();
  public searchSubcription: Subscription = null;
  public expAccountList: any[] = [];
  public openDilogConfirmation = false;
  public AreaZoneVM = new AreaZoneViewModel();
  constructor(public _notificationService: NotificationService,
    public _storeService: StoreService,
    public _settingService: SettingServices,
    private activatedRoute: ActivatedRoute,
    private _localService: LocalService) { }

  ngOnInit() {
    this.initializeProps();
    this.getAreaZoneList();
  }
  private initializeProps() {
    this.kGrid.loading = false;
    this.kGrid.pageSize = 20;
    this.kGrid.pageSizes = [20, 50, 100];
  }
  public getAreaZoneList() {
    try {
      this.kGrid.loading = true;
      this._settingService.getAreaZoneList().subscribe((res: any) => {
        this.areaZoneList = res;
        console.log(res);
        this.kGrid.data = [];
        this.kGrid.data = res;
        this.kGrid.totalRecords = this.kGrid.data.length;
        this.kGrid.gridView = { data: this.kGrid.data, total: this.kGrid.totalRecords };
        this.kGrid.loading = false;
      }, (err) => {
        this._notificationService.notify('danger', 'Something went wrong');
      });
    } catch (error) {
      console.log(error)
    }
  }
  onSubmit() {
    let areaZone: any = Object.assign({}, this.AreaZoneVM);
    this._settingService.saveAreaZone(areaZone).subscribe((res: any) => {
      this.openDilog = false;
      this._notificationService.notify('success', 'Saved Successfully!!');
      this.getAreaZoneList();
      this.AreaZoneVM = new AreaZoneViewModel();
    }, (err) => {
      this.openDilog = false;
      this._notificationService.notify('danger', 'Something went wrong');
    });
  }


  public OpenDialogForEdit(item) {
    console.log(item);
    // this.ve.Id = id;
    this.AreaZoneVM = item;
    this.openDilog = true;
  }


  public ConfirmDelete() {
    this._settingService.removeAreaZone(this.AreaZoneVM).subscribe((res: any) => {
      this.AreaZoneVM = null;
      this.openDilogConfirmation = false;
      this._notificationService.notify('success', 'Delete Successful');
      this.getAreaZoneList();
    }, err => {
      this._notificationService.notify('danger', err.error);

    });
  }

  public RemoveAreaZone(e) {
    this.AreaZoneVM = e;
    this.openDilogConfirmation = true;
  }



  public closeDelete() {
    this.openDilogConfirmation = false;
  }

  public OpenDileogForAreaZone() {
    this.openDilog = true;

  }

  public close(status) {
    this.openDilog = false;
  }


  public changePagesize(value: any) {
    this.kGrid.pageSize = +value;
    this.kGrid.skip = 0;
    // this.getCategory();
  }
  public sortChange(sort: SortDescriptor[]): void {
    if (sort[0].field == 'asd') { return; }
    this.kGrid.sort = sort;
    // this.sortData();
  }
  // private sortData() {
  //   this.kGrid.gridView = {
  //     data: orderBy(this.kGrid.data, this.kGrid.sort),
  //     total: this.kGrid.totalRecords
  //   };
  // }
  public pageChange(event: PageChangeEvent): void {
    this.kGrid.skip = event.skip;
    // this.getCategory();
  }
}
