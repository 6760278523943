import { ItemViewModel } from "../store/store-model";
export class EBOrdersViewModel {
  public Id: number;
  public OrderDate: Date;
  public OrderNumber: string;
  public CustomerNumber: string;
  public CustomerName: string;
  public FK_Customer_Id: ItemViewModel;
  public OrderDetails: string;
  public PaymentLink: string;
  public Remarks: string;
  public FK_EBTasker_Id: ItemViewModel;
  public PaymentMethod: ItemViewModel;
  public OrderAmount: number;
  public EBCharges: number;
  public GatewayCharges: number;
  public PaidAmountToTasker: number;
  public PaidAmountByTasker: number;
  public PaidAmountByBank: number;
  public IsPaidToTasker: boolean;
  public IsPaidByTasker: boolean;
  public IsPaidByBank: boolean;
  public IsReturn: boolean;
  public EmployeAccount_Id: ItemViewModel;
  public FK_ClientId: ItemViewModel;
  public CustomerAccount_Id: ItemViewModel;
  public FK_EBOredr_Id: number;
  public CreditAccount: ItemViewModel;
  public DebitAccount: ItemViewModel;
  public POSAccount: ItemViewModel;
  public FK_Satelments: number;
  public RecevedFromBank:number;
  public EBChargesOrderAmount:number;
  public EBChargesDisplay:number;
  public EBChargesCheck:number;
  public OrderAmountDisplay: number;
  public OrderAmountCheck :number; 
  public IsLock :boolean;
}
export class EBOrderReturn{
  public Id: number;
  public OrderDate: Date;
  public OrderNumber: string;
  public CustomerNumber: string;
  public CustomerName: string;
  public FK_Customer_Id: ItemViewModel;
  public OrderDetails: string;
  public PaymentLink: string;
  public Remarks: string;
  public FK_EBTasker_Id: ItemViewModel;
  public PaymentMethod: ItemViewModel;
  public OrderAmount: number;
  public EBCharges: number;
  public GatewayCharges: number;
  public PaidAmountToTasker: number;
  public PaidAmountByTasker: number;
  public PaidAmountByBank: number;
  public IsPaidToTasker: boolean;
  public IsPaidByTasker: boolean;
  public IsPaidByBank: boolean;
  public IsReturn: boolean;
  public EmployeAccount_Id: ItemViewModel;
  public FK_ClientId: ItemViewModel;
  public CustomerAccount_Id: ItemViewModel;
  public FK_EBOredr_Id: number;
  public CreditAccount: ItemViewModel;
  public DebitAccount: ItemViewModel;
  public POSAccount: ItemViewModel;
  public FK_Satelments: number;
  public RecevedFromBank:number;
  public EBChargesOrderAmount:number;
}
export class EBOrderInstallmentEditViewModel{
  public Id: number;
  public OrderDate: Date;
  public OrderNumber: string;
  public CustomerNumber: string;
  public CustomerName: string;
  public FK_Customer_Id: ItemViewModel;
  public OrderDetails: string;
  public PaymentLink: string;
  public Remarks: string;
  public FK_EBTasker_Id: ItemViewModel;
  public PaymentMethod: ItemViewModel;
  public OrderAmount: number;
  public EBCharges: number;
  public GatewayCharges: number;
  public PaidAmountToTasker: number;
  public PaidAmountByTasker: number;
  public PaidAmountByBank: number;
  public IsPaidToTasker: boolean;
  public IsPaidByTasker: boolean;
  public IsPaidByBank: boolean;
  public IsReturn: boolean;
  public EmployeAccount_Id: ItemViewModel;
  public FK_ClientId: ItemViewModel;
  public CustomerAccount_Id: ItemViewModel;
  public FK_EBOredr_Id: number;
  public CreditAccount: ItemViewModel;
  public DebitAccount: ItemViewModel;
  public POSAccount: ItemViewModel;
  public FK_Satelments: number;
  public RecevedFromBank:number;
  public EBChargesOrderAmount:number;
}
export class BullkEBPOSReturnViewModel {

  public  bulkPaymentList :Array<EBOrdersViewModel>
  public  FK_Tasker_Id :ItemViewModel;
  public  FK_Customer_Id: ItemViewModel; 
  public  Fk_BankAccount :ItemViewModel;
  public  GatewayExpenditureAccount :ItemViewModel;
  public EmployeAccount_Id: ItemViewModel;
  public  CreditAccount :ItemViewModel;
  public  DebitAccount :ItemViewModel;
  public  FK_Client_Id :ItemViewModel;
  public  PaymentMethod :ItemViewModel;
  public  OrderDate :Date;
  public  Attachement:string;
  public  TotalOrderAmount :number;
  public  TotalEBCharges:number;
  public  TotalGatewayCharges:number;
  public  TotalRecevedFrombank:number;
  public  Remarks: string;
  public file:any;
  public SettlementType:number;
}
